import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "../login/Login";
import PrivateRoute from "./PrivateRoute";
import AdminHeader from "./AdminHeader";
import PublicHeader from "./PublicHeader";
import Aktivering from "../brukerkonto/AktiveringContainer";
import ToastContainer from "../toast";
import sitePaths from "./sitePaths";
import AdminNavbar from "./AdminNavbar";
import Kunder from "../kunder";
import Brukere from "../brukere";
import Kjoretoy from "../kjoretoy/kjoretoyContainer";
import Systemkonfigurasjon from "../konfigurasjon";
import Oblattyper from "../oblattyper";
import Oblatregistrering from "../oblatregistrering";
import {
  harKjoretoyTilgangskontroll,
  harApplikasjonsBrukereTilgangskontroll,
  harKonfigurasjonTilgangskontroll,
  harKundeInfoTilgangskontroll,
  harOblatRegistreringsTilgangskontroll,
  harOblattyperTilgangskontroll
} from "../../utils/AuthorizationUtils";
import { useAuth } from "react-oidc-context";
import { useEffect } from "react";
export default function Router() {
  const auth = useAuth();

  useEffect(() => {
    function expired() {
      auth.signoutRedirect();
    }
    auth.events.addAccessTokenExpired(expired);
    return () => auth.events.removeAccessTokenExpired(expired);
  }, [auth, auth.events]);

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route
            key={0}
            path={sitePaths.admin}
            component={() => (
              <AdminHeader isAuthenticated={auth.isAuthenticated} email={auth.user?.profile.email ?? ""} />
            )}
          />
          <Route path="" component={PublicHeader} />
        </Switch>
        <Route path={sitePaths.admin} component={() => <AdminNavbar />} />
        <div className="container-fluid master-content">
          <div className="row">
            <ToastContainer />
          </div>
          <div>
            <div>
              <Switch>
                <Redirect exact from="/" to={sitePaths.brukerePage} />
                <PrivateRoute
                  path={sitePaths.kjoretoy}
                  isAuthenticated={auth.isAuthenticated}
                  component={Kjoretoy}
                  tilgangskontroll={[harKjoretoyTilgangskontroll]}
                />
                <PrivateRoute
                  path={sitePaths.systemkonfigurasjon}
                  isAuthenticated={auth.isAuthenticated}
                  component={Systemkonfigurasjon}
                  tilgangskontroll={[harKonfigurasjonTilgangskontroll]}
                />
                <PrivateRoute
                  path={sitePaths.oblatregistrering}
                  isAuthenticated={auth.isAuthenticated}
                  component={Oblatregistrering}
                  tilgangskontroll={[harOblatRegistreringsTilgangskontroll]}
                />
                <PrivateRoute
                  path={sitePaths.oblattyper}
                  isAuthenticated={auth.isAuthenticated}
                  component={Oblattyper}
                  tilgangskontroll={[harOblattyperTilgangskontroll]}
                />
                <PrivateRoute
                  path={sitePaths.brukerePage}
                  isAuthenticated={auth.isAuthenticated}
                  component={Brukere}
                  tilgangskontroll={[harApplikasjonsBrukereTilgangskontroll]}
                />
                <PrivateRoute
                  path={sitePaths.kunderPage}
                  isAuthenticated={auth.isAuthenticated}
                  component={Kunder}
                  tilgangskontroll={[harKundeInfoTilgangskontroll]}
                />
                <Route path={sitePaths.activateUser} component={Aktivering} />
                <Route path={sitePaths.loginPage} component={Login} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
