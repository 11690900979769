// User + Login
const login = "token";
const validateToken = "validertoken";
const forgotPassword = "glemtpassord";
const activateUser = key => `brukeraktiveringer/${key}`;
const activateUserWithPassword = key => `brukeraktiveringer/${key}/passord`;
const brukerTilgang = brukerId => `prosessroller/brukere/${brukerId}`;

// User + Prosessroller
const brukere = "brukere";

const postBruker = () => "brukere";
// /api/servicer/\{serviceId\}/brukere
const getServicebrukere = () => `brukere`;
const getBruker = brukerId => `brukere/${brukerId}`;

// Kunder
const kunder = "kunder";
const kunderByEpost = epost => `kunder/epost/${epost}`;
const getKunde = brukerId => `kunder/${brukerId}`;
const getOblater = brukerId => `kunder/${brukerId}/oblater`;
const updateKunde = brukerId => `kunder/${brukerId}`;
const deleteKunde = brukerId => `kunder/${brukerId}`;
const getKunderSomOnskerVarslingPaaEpost = () => `${kunder}/epostvarsling`;

// Kundetyper
const kundetyper = "kundetyper";

// OblatTyper
const oblattyper = "oblattyper";

// Public methods on services
const serviceInfo = "serviceInfo";
const serviceClientInfo = adresse => `${adresse}/api/clientinfo`;
const serviceRoller = "roller";

const konfigurasjon = "konfigurasjoner";

export {
  brukere,
  postBruker,
  getServicebrukere,
  getBruker,
  updateKunde,
  deleteKunde,
  getOblater,
  kunder,
  kunderByEpost,
  getKunde,
  kundetyper,
  serviceInfo,
  login,
  validateToken,
  forgotPassword,
  activateUser,
  brukerTilgang,
  serviceRoller,
  serviceClientInfo,
  activateUserWithPassword,
  konfigurasjon,
  oblattyper,
  getKunderSomOnskerVarslingPaaEpost
};
