import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import OblatLog from "./OblatLog";

import { hentOblatLogg as hentOblatLoggKundeAction } from "../../state/kunde";

import "./oblatActions.css";

class OblatLogModal extends Component {
  static propTypes = {
    selectedBrukerId: PropTypes.string.isRequired,
    oblat: PropTypes.shape({}).isRequired,
    hentOblatLogg: PropTypes.func.isRequired
  };

  state = {
    cancelModalOpen: false,
    laster: false,
    logg: []
  };

  toggleOpen = () => {
    const { cancelModalOpen } = this.state;
    const { hentOblatLogg, selectedBrukerId, oblat } = this.props;
    this.setState({
      cancelModalOpen: !cancelModalOpen
    });

    if (!cancelModalOpen) {
      hentOblatLogg(selectedBrukerId, oblat.id).then(res => this.setState({ laster: false, logg: res.data.result }));
    }
  };

  render() {
    const { oblat } = this.props;
    const { cancelModalOpen, laster, logg } = this.state;

    return (
      <>
        <Button className="oblat-action-button" size="sm" color="warning" onClick={this.toggleOpen}>
          Historie
        </Button>
        <Modal className="oblatdetaljer" isOpen={cancelModalOpen} toggle={this.toggleOpen}>
          <ModalHeader toggle={this.toggleOpen}>Oblat historie</ModalHeader>
          <ModalBody>
            <div className="oblataction-sections">
              <OblatLog oblat={oblat} logg={logg} laster={laster} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleOpen}>
              Lukk
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  hentOblatLogg: (brukerId, oblatId) => hentOblatLoggKundeAction(brukerId, oblatId)
});

export default connect(null, mapDispatchToProps)(OblatLogModal);
