import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Oblattyper from "./Oblattyper";
import {
  getOblattyper as getOblattyperAction,
  postOblattype as postOblattypeAction,
  updateOblattype as updateOblattypeAction,
  getKjoretoyTyper as getKjoretoyTyperAction,
  getPerioder as getPerioderAction,
  getOblattyperSelector,
  getPerioderSelector,
  getKjoretoytyperSelector
} from "../../state/oblattyper";

export class OblattypeContainer extends Component {
  static propTypes = {
    oblattyper: PropTypes.arrayOf(PropTypes.shape()),
    perioder: PropTypes.arrayOf(PropTypes.shape()),
    kjoretoytyper: PropTypes.arrayOf(PropTypes.shape()),
    getOblattyper: PropTypes.func.isRequired,
    postOblattype: PropTypes.func.isRequired,
    updateOblattype: PropTypes.func.isRequired,
    getKjoretoytyper: PropTypes.func.isRequired,
    getPerioder: PropTypes.func.isRequired
  };

  static defaultProps = {
    oblattyper: [],
    perioder: [],
    kjoretoytyper: []
  };

  state = {};

  componentDidMount() {
    const { getOblattyper, getKjoretoytyper, getPerioder } = this.props;
    getOblattyper();
    getKjoretoytyper();
    getPerioder();
  }

  render() {
    const { oblattyper, postOblattype, updateOblattype, perioder, kjoretoytyper } = this.props;

    return (
      <Oblattyper
        oblattyper={oblattyper}
        postOblattype={postOblattype}
        updateOblattype={updateOblattype}
        perioder={perioder}
        kjoretoytyper={kjoretoytyper}
      />
    );
  }
}

const mapStateToProps = state => ({
  oblattyper: getOblattyperSelector(state),
  perioder: getPerioderSelector(state),
  kjoretoytyper: getKjoretoytyperSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getOblattyper: () => dispatch(getOblattyperAction()),
  postOblattype: payload => dispatch(postOblattypeAction(payload)),
  updateOblattype: (id, payload) => dispatch(updateOblattypeAction(id, payload)),
  getKjoretoytyper: () => dispatch(getKjoretoyTyperAction()),
  getPerioder: () => dispatch(getPerioderAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(OblattypeContainer);
