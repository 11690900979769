import PropTypes from "prop-types";
import logo from "../../byvaapenet.png";

import "./AdminHeader.css";

interface AdminHeaderProps {
  isAuthenticated: boolean;
  email: string;
}

function AdminHeader({ isAuthenticated, email }: AdminHeaderProps) {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <h1 className="App-title">Piggdekkoblat - Administreringsverktøy</h1>

      {isAuthenticated && <div className="user">Logget inn som: {email}</div>}
    </header>
  );
}

AdminHeader.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

export default AdminHeader;
