import React from "react";
import { Button, Form, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import map from "lodash/map";

import "./AddBruker.css";
import { Rolle } from "./AddBrukerContainer";

interface AddBrukerProps {
  submitting: boolean;
  onAddBruker: React.FormEventHandler<any>;
  onInputChange: React.ChangeEventHandler<HTMLInputElement>;
  serviceRoller: Array<Rolle>;
  error: {
    nameError: string;
    emailError: string;
    rollenavnError: string;
  };
}

export default function AddBruker({
  onAddBruker,
  onInputChange,
  serviceRoller,
  error,
  submitting = false
}: AddBrukerProps) {
  return (
    <div className="addBruker">
      <Form onSubmit={onAddBruker}>
        <FormGroup>
          <Label for="name">Navn</Label>
          <Input
            invalid={error.nameError !== ""}
            type="text"
            name="name"
            placeholder="Navn"
            autoFocus
            onChange={onInputChange}
          />
          <FormFeedback>{error.nameError}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="email">E-post</Label>
          <Input
            invalid={error.emailError !== ""}
            type="text"
            name="email"
            placeholder="E-post"
            onChange={onInputChange}
          />
          <FormFeedback>{error.emailError}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="rollenavn">Tilgangsnivå</Label>
          <Input
            invalid={error.rollenavnError !== ""}
            type="select"
            onChange={onInputChange}
            name="rollenavn"
            placeholder="Velg tilgangsnivå"
          >
            <option>Velg tilgangsnivå</option>
            {map(serviceRoller, option => (
              <option value={option.navn}>{option.navn}</option>
            ))}
          </Input>
          <FormFeedback>{error.rollenavnError}</FormFeedback>
        </FormGroup>
        {submitting && (
          <Button color="primary" disabled>
            Legger til service...
          </Button>
        )}
        {!submitting && (
          <Button color="primary" onClick={onAddBruker}>
            Legg til ny bruker
          </Button>
        )}
      </Form>
    </div>
  );
}
