import React from "react";
import Button from "reactstrap/lib/Button";
import PropTypes from "prop-types";

class Kjoretoy extends React.Component {
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDelete = () => {
    this.props.slettKjoretoyoyFraListe(this.props.kjoretoyId);
  };

  render() {
    const { registreringsnummer, kjoretoytype, beskrivelse, typeOblat, kjoretoyId } = this.props;
    return (
      <tr key={kjoretoyId}>
        <td>{registreringsnummer}</td>
        <td>{kjoretoytype === 2 ? "Tung" : "Lett"}</td>
        <td>{beskrivelse}</td>
        <td>
          <Button color="danger" onClick={this.handleDelete}>
            Slett
          </Button>
        </td>
      </tr>
    );
  }
}

Kjoretoy.propTypes = {
  registreringsnummer: PropTypes.string,
  kjoretoyId: PropTypes.string,
  typeOblat: PropTypes.string,
  kjoretoytype: PropTypes.number.isRequired,
  beskrivelse: PropTypes.string,
  opdaterKjoreotyListeType: PropTypes.func.isRequired,
  slettKjoretoyoyFraListe: PropTypes.func.isRequired
};
Kjoretoy.defaultProps = {
  registreringsnummer: "",
  kjoretoyId: "",
  beskrivelse: "",
  typeOblat: ""
};

export default Kjoretoy;
