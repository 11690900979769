import { NavLink } from "react-router-dom";
import sitePaths from "./sitePaths";

import {
  harApplikasjonsBrukereTilgangskontroll,
  harKjoretoyTilgangskontroll,
  harKonfigurasjonTilgangskontroll,
  harKundeInfoTilgangskontroll,
  harOblatRegistreringsTilgangskontroll,
  harOblattyperTilgangskontroll
} from "../../utils/AuthorizationUtils";

import { useAuth } from "react-oidc-context";

function Show({ show, children }): JSX.Element {
  return show ? <>{children}</> : null;
}

export default function AdminNavbar() {
  const auth = useAuth();

  if (!auth.isAuthenticated)
    return (
      <div className="App-navbar">
        <NavLink to={sitePaths.loginPage}>Logg inn</NavLink>
      </div>
    );

  return (
    <div className="App-navbar">
      <Show show={harKundeInfoTilgangskontroll()}>
        <NavLink activeClassName="navlink-selected" to={sitePaths.kunderPage}>
          Kunder
        </NavLink>
      </Show>
      <Show show={harKjoretoyTilgangskontroll()}>
        <NavLink activeClassName="navlink-selected" to={sitePaths.kjoretoy}>
          Kjøretøy
        </NavLink>
      </Show>
      <Show show={harKonfigurasjonTilgangskontroll()}>
        <NavLink activeClassName="navlink-selected" to={sitePaths.systemkonfigurasjon}>
          Konfigurasjon
        </NavLink>
      </Show>
      <Show show={harOblatRegistreringsTilgangskontroll()}>
        <NavLink activeClassName="navlink-selected" to={sitePaths.oblatregistrering}>
          Oblatregistrering
        </NavLink>
      </Show>
      <Show show={harOblattyperTilgangskontroll()}>
        <NavLink activeClassName="navlink-selected" to={sitePaths.oblattyper}>
          Oblattyper
        </NavLink>
      </Show>
      <Show show={harApplikasjonsBrukereTilgangskontroll()}>
        <NavLink activeClassName="navlink-selected" to={sitePaths.brukerePage}>
          Brukere
        </NavLink>
      </Show>
      <NavLink
        to={sitePaths.loginPage}
        onClick={e => {
          e.preventDefault();
          auth.signoutRedirect();
        }}
      >
        Logg ut
      </NavLink>
    </div>
  );
}
