import { isEmpty } from "lodash-es";
import Api from "../api";

// TYPES
const GET_KUNDER_DATA = "kunder/GET_KUNDER_DATA";
const GET_KUNDER_DATA_ERROR = "kunder/GET_KUNDER_DATA_ERROR";
const SET_LOADING_KUNDER = "kunder/SET_LOADING_KUNDER";

// REDUCER
export default function reducer(
  state = {
    kunder: [],
    kunderError: "",
    loadingKunder: false
  },
  action = {}
) {
  if (action.type === GET_KUNDER_DATA)
    return {
      ...state,
      kunder: action.payload,
      kunderError: ""
    };
  if (action.type === GET_KUNDER_DATA_ERROR)
    return {
      ...state,
      kunder: [],
      kunderError: action.payload
    };
  if (action.type === SET_LOADING_KUNDER)
    return {
      ...state,
      loadingKunder: action.payload
    };
  return state;
}

// ACTIONS
function getKunderSuccess(payload) {
  return { type: GET_KUNDER_DATA, payload };
}

function getKunderError(payload) {
  return { type: GET_KUNDER_DATA_ERROR, payload };
}

function setLoadingKunder(payload) {
  return { type: SET_LOADING_KUNDER, payload };
}

// SELECTORS
export const getKunderSelector = state => state.kunder.kunder;
export const getIsKunderLoading = state => state.kunder.loadingKunder;

// ACTIONS WITH SIDEEFFECTS
export function getKunderData() {
  return (dispatch, getState) => {
    const {
      kunder: { kunder }
    } = getState();
    if (!isEmpty(kunder)) return Promise.resolve(kunder);
    dispatch(setLoadingKunder(true));
    return Api.getAllKunderData()
      .then(response => {
        dispatch(getKunderSuccess(response.data.result));
      })
      .catch(() => {
        dispatch(getKunderError("Kunne ikke hente kundedata"));
      })
      .finally(() => {
        dispatch(setLoadingKunder(false));
      });
  };
}

export function getKunderSokData(epost, brukerId) {
  return dispatch => {
    dispatch(setLoadingKunder(true));
    return Api.getKunderSokData(epost, brukerId)
      .then(response => {
        dispatch(getKunderSuccess(response.data.result));
      })
      .catch(() => {
        dispatch(getKunderError("Kunne ikke hente kundedata"));
      })
      .finally(() => {
        dispatch(setLoadingKunder(false));
      });
  };
}
