import {
  piggavAdmin,
  piggavKunde,
  piggavLesebruker,
  piggavResepsjon,
  piggavServiceavdeling
} from "constants/Authorization";
import jwtDecode from "jwt-decode";
import { getUser, OneloginAccessToken } from "auth/oidc";

const checkIfRoleExistsInAccessToken = (value: string): boolean => {
  const user = getUser();
  if (user?.access_token) {
    const decodedToken = jwtDecode<OneloginAccessToken>(user?.access_token ?? "");
    const accessTokenRoles = decodedToken.roles;
    if (accessTokenRoles.some(role => role === value)) {
      return true;
    }
  }
  return false;
};

export const erBrukerAdmin = (): boolean => checkIfRoleExistsInAccessToken(piggavAdmin);

export const erBrukerServiceAvdelingBruker = (): boolean => checkIfRoleExistsInAccessToken(piggavServiceavdeling);

export const erKundeBruker = (): boolean => checkIfRoleExistsInAccessToken(piggavKunde);

export const erResepsjonsbruker = (): boolean => checkIfRoleExistsInAccessToken(piggavResepsjon);

export const erLesetilgangbruker = (): boolean => checkIfRoleExistsInAccessToken(piggavLesebruker);

export function harKjoretoyTilgangskontroll(): boolean {
  return erBrukerAdmin() || erLesetilgangbruker() || erBrukerServiceAvdelingBruker();
}

export function harKundeInfoTilgangskontroll(): boolean {
  return erBrukerAdmin() || erBrukerServiceAvdelingBruker();
}

export function harApplikasjonsBrukereTilgangskontroll(): boolean {
  return erBrukerAdmin();
}

export function harKonfigurasjonTilgangskontroll(): boolean {
  return erBrukerAdmin();
}

export function harOblatRegistreringsTilgangskontroll(): boolean {
  return erBrukerAdmin();
}

export function harOblattyperTilgangskontroll(): boolean {
  return erBrukerAdmin();
}
