import ReactTable from "react-table";
import { useState } from "react";
import PropTypes from "prop-types";
import { filterIncludeCaseInsensitive } from "../../utils/Helperfunctions";
import KunderSearchForm from "./KunderSearchForm";
import { Button, Spinner } from "reactstrap";
import httpClient from "api/httpClient";
import { generateDownloadDialogue } from "../../utils/csvTools";

function KunderTable({
  kunder,
  loading,
  onBrukerSelected,
  selectedBrukerId,
  filters,
  onKunderFilterChange,
  getKunderSok
}) {
  const [isLoadingKunderSomOnskerPaaminelse, setIsLoadingKunderSomOnskerPaaminelse] = useState(false);
  let noDataText;
  if (loading) {
    noDataText = "Laster ...";
  } else {
    noDataText =
      kunder.length === 0
        ? "Ingen kunder funnet. Legg inn søkeparametere"
        : "Ingen kunder funnet. Legg inn søkeparametere";
  }
  const columns = [
    {
      Header: "E-post",
      accessor: "epost",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Id",
      accessor: "brukerId"
    },
    {
      Header: "Kundetype",
      accessor: "kundetypeId",
      Cell: ({ value }) => (value === 1 && "Privatkunde") || (value === 2 && "Bedriftkunde") || "intern",
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%" }}
            value={filter && filter.value}
            onChange={event => onChange(Number(event.target.value))}
          >
            <option selected>Vis alle</option>
            <option value={1}>Privatkunde</option>
            <option value={2}>Bedriftkunde</option>
          </select>
        );
      },
      filterMethod: (filter, row, col) => {
        if (filter.value === 1) return row[filter.id] === 1;
        if (filter.value === 2) return row[filter.id] === 2;
        return true;
      }
    }
  ];

  async function hentAlleKunderSomOnskerEpostvarsling() {
    try {
      setIsLoadingKunderSomOnskerPaaminelse(true);
      const ks = await httpClient.getKunderSomOnskerVarslingPaaEpost();
      console.log(ks);
      generateDownloadDialogue(ks.data.result.map(k => ({ epost: k.epost, kundeType: k.kundeTypeNavn.toLowerCase() })));
    } finally {
      setIsLoadingKunderSomOnskerPaaminelse(false);
    }
  }

  return (
    <div>
      <KunderSearchForm
        onSearch={values => {
          getKunderSok(values.epost, values.brukerId);
        }}
      />

      <ReactTable
        className="-striped -highlight"
        data={loading ? [] : kunder}
        columns={columns}
        filterable={false}
        noDataText={noDataText}
        getTrProps={(state, rowInfo) => ({
          onClick: (e, handleOriginal) => {
            // ensures that react table internal onClick is called
            if (handleOriginal) {
              handleOriginal();
            }
            onBrukerSelected(rowInfo.original);
          },
          style: rowInfo
            ? {
                background: rowInfo.row.brukerId === selectedBrukerId ? "#5b5b5b" : "",
                color: rowInfo.row.brukerId === selectedBrukerId ? "ghostwhite" : ""
              }
            : {}
        })}
        defaultFiltered={filters}
        onFilteredChange={onKunderFilterChange}
      >
        {(state, makeTable) => {
          let recText = "";

          const { pageRows, pageSize, sortedData, page } = state;

          if (sortedData && sortedData.length > 0) {
            const totRecords = sortedData.length;

            const recCountFrom = page * pageSize + 1;

            const recCountTo = recCountFrom + pageRows.length - 1;

            recText = `${recCountFrom}-${recCountTo} av ${totRecords} kunder`;
          } else recText = "Ingen kunder";

          return (
            <div>
              {makeTable()}
              <div className="text-right">
                <span style={{ color: "white" }}>{recText}</span>
              </div>
            </div>
          );
        }}
      </ReactTable>
      <Button
        color="primary"
        disabled={isLoadingKunderSomOnskerPaaminelse}
        onClick={hentAlleKunderSomOnskerEpostvarsling}
      >
        {isLoadingKunderSomOnskerPaaminelse ? (
          <>
            <span>Laster, vennligst vent, dette vil ta tid!</span>
            <Spinner />
          </>
        ) : (
          "Last ned kunder som ønsker epostvarsling"
        )}
      </Button>
    </div>
  );
}

KunderTable.propTypes = {
  kunder: PropTypes.arrayOf(
    PropTypes.shape({
      brukerId: PropTypes.string,
      epost: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  onBrukerSelected: PropTypes.func.isRequired,
  selectedBrukerId: PropTypes.string,
  filters: PropTypes.shape({}).isRequired,
  onKunderFilterChange: PropTypes.func,
  getKunderSok: PropTypes.func
};

KunderTable.defaultProps = {
  kunder: [],
  loading: false,
  selectedBrukerId: "",
  onKunderFilterChange: () => {}
};

export default KunderTable;
