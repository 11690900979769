import { OBLAT_STATUS } from "constants/Constants";
import EndreOblat from "./EndreOblat";
import RefunderOblat from "./RefunderOblat";
import KrediterOblat from "./KrediterOblat";
import OblatLogModal from "./OblatLogModal";
import "./oblatActions.css";

interface OblatActionsColProps {
  showOnlyThisSeason: boolean;
  selectedBrukerId: string;
  brukerrolleServiceAvdelingBruker: boolean;
  brukerrolleErAdmin: boolean;
  oblat: any;
}

const OblatActionsCol = ({
  showOnlyThisSeason,
  selectedBrukerId,
  oblat,
  brukerrolleErAdmin,
  brukerrolleServiceAvdelingBruker
}: OblatActionsColProps) => {
  if (!(oblat && oblat.kjopsinfo)) return null;

  const kanKreditereEllerRefundere = () => brukerrolleErAdmin && oblat.statusId !== OBLAT_STATUS.OVERFORT;

  const kanOverfoereOblat = () => brukerrolleErAdmin || brukerrolleServiceAvdelingBruker;

  const kanSeHistorikk = () => brukerrolleErAdmin || brukerrolleServiceAvdelingBruker;

  return (
    <div className="oblat-action-col">
      {kanOverfoereOblat() && (
        <EndreOblat
          editable={oblat.statusId === OBLAT_STATUS.AVVENTER_BETALING || oblat.statusId === OBLAT_STATUS.AKTIV}
          selectedBrukerId={selectedBrukerId}
          oblat={oblat}
          showOnlyThisSeason={showOnlyThisSeason}
        />
      )}

      {kanKreditereEllerRefundere() && oblat.kjopsinfo.betalingsform.toUpperCase() === "KORT" && (
        <RefunderOblat
          editable={
            brukerrolleErAdmin && (oblat.statusId === OBLAT_STATUS.AKTIV || oblat.statusId === OBLAT_STATUS.UTLOEPT)
          }
          selectedBrukerId={selectedBrukerId}
          oblat={oblat}
          showOnlyThisSeason={showOnlyThisSeason}
        />
      )}
      {kanKreditereEllerRefundere() && oblat.kjopsinfo.betalingsform.toUpperCase() === "FAKTURA" && (
        <KrediterOblat
          editable={
            brukerrolleErAdmin &&
            (oblat.statusId === OBLAT_STATUS.AKTIV ||
              oblat.statusId === OBLAT_STATUS.AVVENTER_BETALING ||
              oblat.statusId === OBLAT_STATUS.UTLOEPT)
          }
          selectedBrukerId={selectedBrukerId}
          oblat={oblat}
          showOnlyThisSeason={showOnlyThisSeason}
        />
      )}
      {kanSeHistorikk() && <OblatLogModal selectedBrukerId={selectedBrukerId} oblat={oblat} />}
    </div>
  );
};

export default OblatActionsCol;
