import axios from "axios";
import * as urls from "./urls";
import { getEnvironmentVariable } from "../utils/environment";
import { getUser } from "auth/oidc";

const defaultTransformers = () => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) return [];
  if (transformRequest instanceof Array) return transformRequest;
  return [transformRequest];
};

class HttpClient {
  axiosInstance = axios;

  baseUrl;

  access_token;

  store;

  serviceId;

  clientId;

  constructor() {
    this.serviceId = "8bc3fe29-d50b-4f30-b000-a04315044a5a";
    this.clientId = "127625a2-4910-4aae-aa35-91e7b4190c55";

    if (typeof window !== "undefined") {
      this.baseUrl = `${getEnvironmentVariable("REACT_APP_URL_PIGGDEKKOBLAT_SERVICE")}/api/`;
    }

    axios.defaults.baseURL = this.baseUrl;

    axios.defaults.transformRequest = [
      (data, headers) => {
        const accessToken = getUser()?.access_token;
        delete headers.Authorization;
        if (accessToken) headers.Authorization = `Bearer ${accessToken}`;
        else headers.Authorization = "";
        return data;
      },
      ...defaultTransformers()
    ];
  }

  updateErrorInStore = error => {
    if (this.store) {
      // this.store.dispatch(toastError(error));
    }
    return new Promise((resolve, reject) => reject(error));
  };

  get(...args) {
    return this.axiosInstance.get(...args).catch(this.updateErrorInStore);
  }

  post(...args) {
    return this.axiosInstance.post(...args).catch(this.updateErrorInStore);
  }

  put(...args) {
    return this.axiosInstance.put(...args).catch(this.updateErrorInStore);
  }

  delete(...args) {
    return this.axiosInstance.delete(...args).catch(this.updateErrorInStore);
  }

  getKundedata(brukerId) {
    return axios.get(`${this.baseUrl}${urls.getKunde(brukerId)}`);
  }

  getBrukerByEpost(epost) {
    return axios.get(`${this.baseUrl}${urls.kunderByEpost(epost)}`);
  }

  getKunderSomOnskerVarslingPaaEpost() {
    return axios.get(`${this.baseUrl}${urls.getKunderSomOnskerVarslingPaaEpost()}`);
  }

  getAllKunder() {
    return axios.get(`${this.baseUrl}kunder`);
  }

  getKunderSok(epost, brukerId) {
    const epostParameter = epost || "";
    const brukerIdParameter = brukerId || "";
    return axios.get(
      `${this.baseUrl}kunder/sok?epost=${encodeURIComponent(epostParameter)}&brukerid=${encodeURIComponent(
        brukerIdParameter
      )}`
    );
  }

  getKundetyper() {
    return axios.get(`${this.baseUrl}${urls.kundetyper}`);
  }

  postBruker(payload) {
    return axios.post(`${this.baseUrl}${urls.postBruker()}`, payload);
  }

  getServicebrukere = () => {
    return axios.get(`${this.baseUrl}${urls.getServicebrukere()}`);
  };

  getBruker(brukerId) {
    return axios.get(`${this.autentiseringBaseUrl}${urls.getBruker(brukerId)}`);
  }

  updateKunde(brukerId, payload) {
    return axios.put(`${this.baseUrl}${urls.updateKunde(brukerId)}`, payload);
  }

  deleteKunde(brukerId) {
    return axios.delete(`${this.baseUrl}${urls.deleteKunde(brukerId)}`);
  }

  getOblaterForKunde(brukerId, kunInnevaerendeSesong) {
    return axios.get(
      `${this.baseUrl}${urls.getOblater(brukerId)}${kunInnevaerendeSesong ? "?kunInnevaerendeSesong=true" : ""}`
    );
  }

  getserviceRoller() {
    return axios.get(`${this.baseUrl}${urls.serviceRoller}`);
  }

  getSystemkonfigurasjon() {
    return axios.get(`${this.baseUrl}${urls.konfigurasjon}`);
  }

  postSystemkonfigurasjon(navn, kategori, verdi) {
    const payload = { navn, kategori, verdi };
    return axios.post(`${this.baseUrl}${urls.konfigurasjon}`, payload);
  }

  deleteSystemkonfigurasjon(id) {
    return axios.delete(`${this.baseUrl}${urls.konfigurasjon}/${id}`);
  }

  putSystemkonfigurasjon(id, payload) {
    return axios.put(`${this.baseUrl}${urls.konfigurasjon}/${id}`, payload);
  }

  getSystemkonfigurasjonById(id) {
    return axios.get(`${this.baseUrl}${urls.konfigurasjon}/${id}`);
  }

  getOblattyper() {
    return axios.get(`${this.baseUrl}${urls.oblattyper}`);
  }

  postOblattype(payload) {
    return axios.post(`${this.baseUrl}${urls.oblattyper}`, payload);
  }

  putOblattyper(id, payload) {
    return axios.put(`${this.baseUrl}${urls.oblattyper}/${id}`, payload);
  }

  getKjoretoyTyper() {
    return axios.get(`${this.baseUrl}kjoretoytyper`);
  }

  getPerioder() {
    return axios.get(`${this.baseUrl}perioder`);
  }

  getKjoretoyForKunde(brukerId) {
    return axios.get(`${this.baseUrl}kunder/${brukerId}/kjoretoy`);
  }

  kansellerOblatForKunde(brukerId, varekjopId, oblatId, kommentar, resterendeRefusjon, refusjonsdato, belop) {
    return axios.post(`${this.baseUrl}kunder/${brukerId}/varekjop/${varekjopId}/oblater/${oblatId}/manuell-refusjon`, {
      kommentar,
      resterendeRefusjon,
      refusjonsdato,
      belop
    });
  }

  krediterOblatForKunde(brukerId, varekjopId, oblatId, kommentar, resterendeRefusjon, refusjonsdato, belop) {
    return axios.post(
      `${this.baseUrl}kunder/${brukerId}/varekjop/${varekjopId}/oblater/${oblatId}/manuell-kreditnota`,
      { kommentar, resterendeRefusjon, refusjonsdato, belop }
    );
  }

  getKjoreotyMedOblater(regnummer, kunInnevaerendeSesong) {
    return axios.get(
      `${this.baseUrl}registreringsnummer/${regnummer}/oblater${
        kunInnevaerendeSesong ? "?kunInnevaerendeSesong=true" : ""
      }`
    );
  }

  hentOblatLogg(brukerId, oblatId) {
    return axios.get(
      `${this.baseUrl}kunder/${brukerId}/oblater/${oblatId}/logg
      `
    );
  }

  hentKjoretoyForBruker(brukerId) {
    return axios.get(`${this.baseUrl}kunder/${brukerId}/kjoretoy`);
  }

  hentKjoretoyTyper() {
    return axios.get(`${this.baseUrl}kjoretoytyper`);
  }

  endreOblatForBruker(kundeId, oblatId, kjoretoyId, kommentar) {
    return axios.put(`${this.baseUrl}kunder/${kundeId}/oblater/${oblatId}/kjoretoy`, {
      kjoretoyId,
      kommentar
    });
  }

  opprettKjoretoyForBruker(brukerId, regnummer, type) {
    return axios.post(`${this.baseUrl}kunder/${brukerId}/kjoretoy`, {
      kjoretoytypeId: type,
      beskrivelse: "",
      registreringsnummer: regnummer
    });
  }

  postKjoretoyliste(brukerId, fil) {
    return axios.post(`${this.baseUrl}kunder/${brukerId}/filer/kjoretoyliste/fritak`, fil);
  }

  confirmKjoretoyliste(brukerId, kjoretoyliste, oblattype) {
    return axios.post(`${this.baseUrl}kunder/${brukerId}/varekjop/oblater/bulk/fritak`, {
      KjoretoyMedKjoretoytype: kjoretoyliste,
      oblattype
    });
  }

  kalkulerRefusjonsbelopForOblat(brukerId, varekjopId, oblatId, refusjonsdato) {
    return axios.get(
      `${this.baseUrl}kunder/${brukerId}/varekjop/${varekjopId}/oblater/${oblatId}/kalkuler?refusjonsdato=${new Date(
        refusjonsdato
      ).toISOString()}`
    );
  }
}
const httpClient = new HttpClient();
export default httpClient;
