import React from "react";

function BrukerePageHeader() {
  return (
    <div className="component-header">
      <h2>Kunder</h2>
    </div>
  );
}

export default BrukerePageHeader;
