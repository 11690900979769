import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import map from "lodash/map";
import "react-datepicker/dist/react-datepicker.css";

class NyOblattypeForm extends Component {
  static propTypes = {
    postOblattype: PropTypes.func.isRequired,
    perioder: PropTypes.arrayOf(PropTypes.shape({})),
    kjoretoytyper: PropTypes.arrayOf(PropTypes.shape({}))
  };

  static defaultProps = {
    perioder: [],
    kjoretoytyper: []
  };

  state = {
    navn: "",
    periodeId: 1,
    pris: 0,
    statusId: "2",
    utloper: "",
    kjoretoytypeId: "1",
    agressoArtikkelkode: ""
  };

  handleSave = () => {
    const { navn, periodeId, pris, statusId, utloper, kjoretoytypeId, agressoArtikkelkode } = this.state;

    this.props
      .postOblattype({
        navn,
        periodeId,
        pris,
        statusId,
        utloper,
        kjoretoytypeId,
        agressoArtikkelkode
      })
      .then(() =>
        this.setState({
          navn: "",
          periodeId: 1,
          pris: 0,
          statusId: "2",
          utloper: "",
          kjoretoytypeId: "1",
          agressoArtikkelkode: ""
        })
      );
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const {
      navn,
      periodeId,
      pris,
      statusId,
      // utloper,
      kjoretoytypeId,
      agressoArtikkelkode
    } = this.state;
    const { perioder, kjoretoytyper } = this.props;
    return (
      <div className="oblattyper-add-form">
        <h3>Legg til oblattype</h3>
        <div className="oblattyper-add-box">
          <span className="oblattype-input-label">Navn</span>
          <input
            className="oblattype-input"
            type="text"
            id="navn"
            placeholder="navn"
            value={navn}
            onChange={this.handleChange}
          />
        </div>
        <div className="oblattyper-add-box">
          <span className="oblattype-input-label">Periode</span>
          <select
            className="oblattype-input"
            type="text"
            id="periodeId"
            placeholder="periodeId"
            value={periodeId}
            onChange={this.handleChange}
          >
            {map(perioder, periode => (
              <option value={periode.id}>{periode.navn}</option>
            ))}
          </select>
        </div>
        <div className="oblattyper-add-box">
          <span className="oblattype-input-label">Pris</span>
          <input
            className="oblattype-input"
            type="text"
            id="pris"
            placeholder="pris"
            value={pris}
            onChange={this.handleChange}
          />
        </div>
        <div className="oblattyper-add-box">
          <span className="oblattype-input-label">StatusId</span>
          <select
            className="oblattype-input"
            type="text"
            placeholder="statusId"
            id="statusId"
            value={statusId}
            onChange={this.handleChange}
          >
            <option value="2">Ikke aktiv</option>
            <option value="1">Aktiv</option>
            <option value="3">Skjult</option>
          </select>
        </div>
        {/* <div className="oblattyper-add-box">
          <span className="oblattype-input-label">Utloper</span>
          <input
            className="oblattype-input"
            type="text"
            placeholder="utloper"
            id="utloper"
            value={utloper}
            onChange={this.handleChange}
          />
        </div> */}
        <div className="oblattyper-add-box">
          <span className="oblattype-input-label">Kjoretoytype</span>
          <select
            className="oblattype-input"
            type="text"
            placeholder="kjoretoytypeId"
            id="kjoretoytypeId"
            value={kjoretoytypeId}
            onChange={this.handleChange}
          >
            {map(kjoretoytyper, kjoretoyType => (
              <option value={kjoretoyType.id}>{kjoretoyType.navn}</option>
            ))}
          </select>
        </div>
        <div className="oblattyper-add-box">
          <span className="oblattype-input-label">Agresso artikkelkode</span>
          <input
            className="oblattype-input"
            type="text"
            placeholder="agressoArtikkelkode"
            id="agressoArtikkelkode"
            value={agressoArtikkelkode}
            onChange={this.handleChange}
          />
        </div>

        <button className="oblattype-button" onClick={this.handleSave}>
          Lagre
        </button>
      </div>
    );
  }
}

export default withRouter(NyOblattypeForm);
