// eslint-disable-next-line import/prefer-default-export
export const OBLAT_STATUS = {
  AKTIV: 1,
  INAKTIV: 2,
  REFUNDERT: 3,
  AVVENTER_BETALING: 4,
  KREDITERT: 5,
  KANSELLERT: 6,
  OVERFORT: 8,
  UTLOEPT: 10
};

export const PIGGDEKKOBLAT_PORTAL_CLIENT_ID = "6124bd05-7627-43d4-9d99-d6d356da0d3e";

export enum PeriodeId {
  DAG = 1,
  MAANED = 2,
  SESONG = 3
}
