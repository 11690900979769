import { Component } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Button } from "reactstrap";
import AddBruker from "./AddBruker";
import { addBruker as addBrukerAction, getServicebrukere } from "../../state/brukere";
import { getServiceRoller as getServiceRollerAction, getServiceRollerSelector } from "../../state/serviceRoller";

export type Rolle = { navn: string };
interface AddBrukerContainerProps extends RouteComponentProps {
  addBruker: (rollenavn: string, name: string, email: string) => any;
  getServiceRoller: () => Promise<any>;
  serviceRoller: Array<Rolle>;
  getServicebrukereProp: () => Promise<any>;
}

class AddBrukerContainer extends Component<AddBrukerContainerProps> {
  state = {
    showForm: false,
    submitting: false,
    error: {
      rollenavnError: "",
      nameError: "",
      emailError: ""
    },
    rollenavn: "",
    name: "",
    email: ""
  };

  componentDidMount = () => {
    const { getServiceRoller } = this.props;
    getServiceRoller();
  };

  handleSubmitBruker = event => {
    const { addBruker, getServicebrukereProp } = this.props;
    const { rollenavn, name, email } = this.state;
    event.preventDefault();

    const rollenavnError = !rollenavn ? "Velg tilgangsnivå" : "";
    const nameError = name === "" ? "Skriv inn navn" : "";
    const emailError = email === "" ? "Skriv inn e-post" : "";
    if (rollenavnError || nameError || emailError)
      this.setState({
        error: {
          rollenavnError,
          nameError,
          emailError
        }
      });
    else {
      this.setState({ showForm: false });
      addBruker(rollenavn, name, email).then(() => {
        getServicebrukereProp();
      });
    }
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { serviceRoller } = this.props;
    const { showForm, submitting, error } = this.state;
    if (showForm) {
      return [
        <Button
          onClick={() =>
            this.setState({
              showForm: false,
              submitting: false,
              error: {
                prosessrolleIdError: "",
                nameError: "",
                emailError: ""
              }
            })
          }
        >
          Avbryt
        </Button>,
        <AddBruker
          onAddBruker={this.handleSubmitBruker}
          onInputChange={this.handleInputChange}
          submitting={submitting}
          serviceRoller={serviceRoller}
          error={error}
        />
      ];
    }
    return <Button onClick={() => this.setState({ showForm: true })}>Legg til ny bruker</Button>;
  }
}

const mapStateToProps = state => ({
  serviceRoller: getServiceRollerSelector(state)
});

const mapDispatchToProps = dispatch => ({
  addBruker: (rollenavn: string, name: string, email: string) => dispatch(addBrukerAction(rollenavn, name, email)),
  getServiceRoller: () => dispatch(getServiceRollerAction()),
  getServicebrukereProp: () => dispatch(getServicebrukere())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBrukerContainer));
