import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "reactstrap/lib/Button";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import Alert from "reactstrap/lib/Alert";
import { OBLAT_STATUS } from "constants/Constants";

const MengdekjopForm = ({
  oblattype,
  kjoreotylisteLength,
  kjoretoyliste,
  uploadFile,
  onRadioBtnClick,
  confirmKjoretoyliste,
  resetKjoretoyliste,
  oblater
}) => {
  const [duplicates, setDuplicates] = useState([]);

  useEffect(() => {
    const validateRegnummer = kjoretoys => {
      setDuplicates(
        kjoretoys
          .filter(kjoretoy =>
            oblater.some(
              oblat =>
                kjoretoy.registreringsnummer.toUpperCase() === oblat.kjoretoy.registreringsnummer.toUpperCase() &&
                oblat.kjoretoy.status === OBLAT_STATUS.AKTIVS
            )
          )
          .map(kjoretoy => kjoretoy.registreringsnummer)
      );
    };

    if (kjoretoyliste) {
      validateRegnummer(kjoretoyliste);
    }
  }, [kjoretoyliste, oblater]);

  return (
    <div className="oblatregistrering-container">
      <h3>Last opp kjøretøyliste</h3>
      <div className="oblatregistrering-body">
        <div>
          <p>Kjøretøyliste</p>
          <form onSubmit={uploadFile}>
            <input
              type="file"
              name="bulkKjopImportExcelInput"
              id="bulkKjopImportExcelInput"
              accept=".txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <Button type="submit" color="primary" className="btn-bym-standard">
              Last opp fil
            </Button>
          </form>
        </div>
        <div>
          <p>Oblattype</p>
          <ButtonGroup>
            <Button color="primary" onClick={() => onRadioBtnClick("DIPLOMAT")} active={oblattype === "DIPLOMAT"}>
              Diplomatbil
            </Button>
            <Button color="primary" onClick={() => onRadioBtnClick("UTRYKNING")} active={oblattype === "UTRYKNING"}>
              Utrykkning
            </Button>
          </ButtonGroup>
        </div>
        <div className="oblatregistrering-body-submit">
          {(kjoreotylisteLength === 0 || oblattype === "") && (
            <Alert color="info">*Last opp en fil og velg oblattype</Alert>
          )}
          {duplicates.length > 0 && (
            <Alert color="warning">Kjøretøy er registrert fra før; {duplicates.toString()}</Alert>
          )}
          <Button
            color="success"
            disabled={kjoreotylisteLength === 0 || oblattype === ""}
            onClick={confirmKjoretoyliste}
          >
            Lagre
          </Button>

          <Button color="danger" disabled={kjoreotylisteLength === 0} onClick={resetKjoretoyliste}>
            Avbryt
          </Button>
        </div>
      </div>
    </div>
  );
};

MengdekjopForm.propTypes = {
  oblattype: PropTypes.shape({}).isRequired,
  kjoreotylisteLength: PropTypes.number.isRequired,
  kjoretoyliste: PropTypes.arrayOf(PropTypes.shape({})),
  uploadFile: PropTypes.func.isRequired,
  onRadioBtnClick: PropTypes.func.isRequired,
  confirmKjoretoyliste: PropTypes.func.isRequired,
  resetKjoretoyliste: PropTypes.func.isRequired,
  oblater: PropTypes.arrayOf(PropTypes.shape({}))
};

export default MengdekjopForm;
