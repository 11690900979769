import { Component } from "react";
import { connect } from "react-redux";
import Systemkonfigurasjon from "./Systemkonfigurasjon";
import {
  getSystemkonfigurasjon as getSystemkonfigurasjonAction,
  postSystemkonfigurasjon as postSystemkonfigurasjonAction,
  updateSystemkonfigurasjon as updateSystemkonfigurasjonAction,
  getSystemkonfigurasjonerSelector
} from "../../state/systemkonfigurasjon";
import { Systemkonfigurasjon as ISystekmkonfigurasjon } from "api/interfaces";

interface KonfigurasjonContainerProps {
  systemkonfigurasjon: Array<ISystekmkonfigurasjon>;
  getSystemkonfigurasjon: Function;
  postSystemkonfigurasjon: Function;
  updateSystemkonfigurasjon: Function;
}

export class KonfigurasjonContainer extends Component<KonfigurasjonContainerProps> {
  public constructor(props: KonfigurasjonContainerProps) {
    super(props);
  }

  static defaultProps = { systemkonfigurasjon: [] };

  componentDidMount() {
    const { getSystemkonfigurasjon } = this.props;
    getSystemkonfigurasjon();
  }

  render() {
    const { systemkonfigurasjon, postSystemkonfigurasjon, updateSystemkonfigurasjon } = this.props;
    return (
      <Systemkonfigurasjon
        systemkonfigurasjon={systemkonfigurasjon}
        updateSystemkonfigurasjon={updateSystemkonfigurasjon}
        postSystemkonfigurasjon={postSystemkonfigurasjon}
      />
    );
  }
}

const mapStateToProps = state => ({
  systemkonfigurasjon: getSystemkonfigurasjonerSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getSystemkonfigurasjon: () => dispatch(getSystemkonfigurasjonAction()),
  postSystemkonfigurasjon: (navn, kategori, verdi) => dispatch(postSystemkonfigurasjonAction(navn, kategori, verdi)),
  updateSystemkonfigurasjon: (id, payload) => dispatch(updateSystemkonfigurasjonAction(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(KonfigurasjonContainer);
