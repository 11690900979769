import { Component } from "react";
import { connect } from "react-redux";
import Oblatregistrering from "./Oblatregistrering";
import {
  getOblattyper as getOblattyperAction,
  getKjoretoyTyper as getKjoretoyTyperAction,
  getOblattyperSelector,
  getKjoretoytyperSelector
} from "../../state/oblattyper";

import {
  opdaterKjoreotyListeType as opdaterKjoreotyListeTypeAction,
  slettKjoretoyoyFraListe as slettKjoretoyoyFraListeAction,
  postKjoretoyliste as postKjoretoylisteAction,
  confirmKjoretoyliste as confirmKjoretoylisteAction,
  resetKjoretoyliste as resetKjoretoylisteAction,
  getOblaterForAdmin as getOblaterForAdminAction,
  getAdminOblaterSelector,
  getKjoretoylisteSelector
} from "../../state/oblatKjop";
import { withAuth } from "react-oidc-context";
import { getBymBrukerId } from "auth/oidc";

interface OblatregistreringContainerProps {
  bymId?: string;
  oblater: Array<any>;
  kjoreotyliste: Array<any>;
  getOblattyper: Function;
  postKjoretoyliste: Function;
  confirmKjoretoyliste: Function;
  getKjoretoytyper: Function;
  opdaterKjoreotyListeType: Function;
  slettKjoretoyoyFraListe: Function;
  resetKjoretoyliste: Function;
  getOblaterForAdmin: Function;
}

export class OblatregistreringContainer extends Component<OblatregistreringContainerProps> {
  static defaultProps = {
    oblattyper: [],
    kjoretoytyper: [],
    kjoreotyliste: [],
    oblater: []
  };

  state = {};

  componentDidMount() {
    const { getOblattyper, getKjoretoytyper } = this.props;
    getOblattyper();
    getKjoretoytyper();
  }

  render() {
    const {
      postKjoretoyliste,
      kjoreotyliste,
      bymId,
      opdaterKjoreotyListeType,
      slettKjoretoyoyFraListe,
      resetKjoretoyliste,
      confirmKjoretoyliste,
      getOblaterForAdmin,
      oblater
    } = this.props;

    return (
      <Oblatregistrering
        bymId={bymId}
        postKjoretoyliste={postKjoretoyliste}
        kjoreotyliste={kjoreotyliste}
        opdaterKjoreotyListeType={opdaterKjoreotyListeType}
        slettKjoretoyoyFraListe={slettKjoretoyoyFraListe}
        resetKjoretoyliste={resetKjoretoyliste}
        confirmKjoretoyliste={confirmKjoretoyliste}
        getOblaterForAdmin={getOblaterForAdmin}
        oblater={oblater}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  bymId: getBymBrukerId(ownProps?.user?.access_token),
  oblattyper: getOblattyperSelector(state),
  kjoretoytyper: getKjoretoytyperSelector(state),
  kjoreotyliste: getKjoretoylisteSelector(state),
  oblater: getAdminOblaterSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getOblattyper: () => dispatch(getOblattyperAction()),
  getKjoretoytyper: () => dispatch(getKjoretoyTyperAction()),
  postKjoretoyliste: (brukerId, fil) => dispatch(postKjoretoylisteAction(brukerId, fil)),
  confirmKjoretoyliste: (brukerId, kjoretoyliste, oblattype) =>
    dispatch(confirmKjoretoylisteAction(brukerId, kjoretoyliste, oblattype)),
  opdaterKjoreotyListeType: oblattype => dispatch(opdaterKjoreotyListeTypeAction(oblattype)),
  slettKjoretoyoyFraListe: kjoreotyId => dispatch(slettKjoretoyoyFraListeAction(kjoreotyId)),
  resetKjoretoyliste: () => dispatch(resetKjoretoylisteAction()),
  getOblaterForAdmin: (brukerId, kunInnevaerendeSesong) =>
    dispatch(getOblaterForAdminAction(brukerId, kunInnevaerendeSesong))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(OblatregistreringContainer));
