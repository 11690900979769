import { useMemo } from "react";
import ReactTable from "react-table";
export interface Bruker {
  brukerId: string;
  navn: string;
  epost: string;
  roller: Array<string>;
}
interface BrukereTableProps {
  selectedBrukerId: string;
  brukere: Array<Bruker>;
  loading: boolean;
}

export default function BrukereTable({ brukere = [], loading = false, selectedBrukerId = "" }: BrukereTableProps) {
  const brukerData = useMemo(() => {
    return brukere.map(b => ({ ...b, roller: b.roller.join(", ") }));
  }, [brukere]);

  let noDataText;
  if (loading) {
    noDataText = "Laster brukere...";
  } else {
    noDataText = brukere.length === 0 ? "Fant ingen brukere på serveren" : "Fant ingen brukere";
  }

  const filterIncludeCaseInsensitive = (f, row) =>
    f && f.value && row && row[f.id] && row[f.id].toLowerCase().includes(f.value.toLowerCase());

  const columns = [
    {
      Header: "E-post",
      accessor: "epost",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Navn",
      accessor: "navn",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Rolle",
      accessor: "roller",
      filterMethod: filterIncludeCaseInsensitive
    }
  ];

  return (
    <ReactTable
      className="-striped -highlight"
      data={brukerData}
      columns={columns}
      filterable
      noDataText={noDataText}
    />
  );
}
