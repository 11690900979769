import React, { useState } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import moment from "moment";
import ReactTable from "react-table";
import { Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { filterIncludeCaseInsensitive } from "../../utils/Helperfunctions";
import { erBrukerAdmin, erBrukerServiceAvdelingBruker } from "../../utils/AuthorizationUtils";

import "./Kjoretoy.css";

const Kjoretoy = ({ kjoreoty, regnummer, handleChange, getOblaterForKjoretoy, onFilterChange, filters }) => {
  const [kunInnevaerendeSesong, setKunInnevaerendeSesong] = useState(true);
  const filterDate = (f, row) => {
    const dateFilter = moment(f.value, moment.ISO_8601);
    const date = moment(row[f.id], moment.ISO_8601);
    return dateFilter.isValid() ? date.isSame(dateFilter, "day") : true;
  };

  const columns = [
    {
      Header: "Oblattype",
      accessor: "oblattype",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Er overfort",
      accessor: "erOverfortString",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "GyldigFra",
      accessor: "gyldigFra",
      filterMethod: filterDate,
      minWidth: 140,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 140 }}
        />
      )
    },
    {
      Header: "GyldigTil",
      accessor: "gyldigTil",
      filterMethod: filterDate,
      minWidth: 140,
      // filterable: false,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 140 }}
        />
      )
    },
    {
      Header: "Kjoretoytype",
      accessor: "kjoretoytype",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Registreringsnummer",
      accessor: "kjoretoy.registreringsnummer",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Beskrivelse",
      accessor: "kjoretoy.beskrivelse",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Status",
      accessor: "status",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Kjøpsdato",
      accessor: "kjopsinfo.kjopsdato",
      filterMethod: filterDate,
      minWidth: 140,
      show: erBrukerServiceAvdelingBruker() || erBrukerAdmin(),
      // filterable: false,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 140 }}
        />
      )
    },
    {
      Header: "Betalingsdato",
      show: erBrukerServiceAvdelingBruker() || erBrukerAdmin(),
      accessor: "kjopsinfo.betalingsdato",
      filterMethod: filterDate,
      minWidth: 140,
      // filterable: false,
      Cell: row => (
        <span>{row.value ? moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm") : "Avventer betaling"}</span>
      ),
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 140 }}
        />
      )
    },
    {
      Header: "Betalingsform",
      show: erBrukerServiceAvdelingBruker() || erBrukerAdmin(),
      accessor: "kjopsinfo.betalingsform",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Pris",
      show: erBrukerServiceAvdelingBruker() || erBrukerAdmin(),
      accessor: "kjopsinfo.price",
      minWidth: 50
    },
    {
      Header: "Ordrenr",
      show: erBrukerServiceAvdelingBruker() || erBrukerAdmin(),
      accessor: "faktura.fakturaNummer",
      minWidth: 100
    },
    {
      show: erBrukerServiceAvdelingBruker() || erBrukerAdmin(),
      Header: "   ",
      accessor: "kjopsinfo.kundeId",
      filterable: false,

      Cell: row =>
        erBrukerAdmin() || erBrukerServiceAvdelingBruker() ? (
          <span>
            <Link
              to={{
                pathname: `kunder/${row.value}`,
                state: { bruker: { brukerId: row.value } }
              }}
            >
              Se bruker
            </Link>
          </span>
        ) : null
    }
  ];

  const data = kjoreoty
    ? map(kjoreoty, item => {
        return {
          ...item,
          kjoretoytype: item.kjoretoy.kjoretoytypeId === 1 ? "Lett" : "Tung",
          erOverfortString: item.erOverfort ? "Ja" : "Nei"
        };
      })
    : [];

  return (
    <section className="kjoretoy-page">
      <h1 className="kjoretoy-title">Kjøretøy</h1>
      <div className="kjoretoy-input-section">
        <div className="input-og-knapp">
          <div>
            <Input
              type="text"
              value={regnummer}
              onChange={e => handleChange("regnummer", e.target.value)}
              onKeyDown={e => (e.keyCode === 13 ? getOblaterForKjoretoy(kunInnevaerendeSesong) : null)}
              autoFocus
            />
          </div>
          <Button
            onClick={() => getOblaterForKjoretoy(kunInnevaerendeSesong)}
            disabled={regnummer === ""}
            color="primary"
          >
            Hent kjøretøy
          </Button>
        </div>
        <div className="kjoretoy-input-filter-option">
          <input
            type="checkbox"
            checked={kunInnevaerendeSesong}
            onChange={() => setKunInnevaerendeSesong(!kunInnevaerendeSesong)}
          />{" "}
          Kun inneværende sesong
        </div>
      </div>

      <div className="kjoretoy-input-section-table">
        <ReactTable
          className="-striped -highlight"
          data={data}
          columns={columns}
          defaultFiltered={filters}
          onFilteredChange={onFilterChange}
          filterable
          noDataText=""
        />
      </div>
    </section>
  );
};

Kjoretoy.propTypes = {
  oblattyper: PropTypes.arrayOf(PropTypes.shape({})),
  perioder: PropTypes.arrayOf(PropTypes.shape({})),
  kjoretoytyper: PropTypes.arrayOf(PropTypes.shape({})),
  kjoreoty: PropTypes.arrayOf(PropTypes.shape({})),
  // postOblattype: PropTypes.func.isRequired
  getOblaterForKjoretoy: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  regnummer: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func,
  filters: PropTypes.shape({}).isRequired
};

Kjoretoy.defaultProps = {
  oblattyper: [],
  perioder: [],
  kjoretoytyper: [],
  kjoreoty: [],
  onFilterChange: () => {}
};

export default Kjoretoy;
