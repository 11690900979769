import React, { useState } from "react";
import PropTypes from "prop-types";
import { OBLAT_STATUS } from "constants/Constants";

import { Button, ButtonGroup, FormGroup, Alert, Label, Input } from "reactstrap";

const EnkeltkjopForm = ({
  onChange,
  singleKjoretoy,
  singleBeskrivelse,
  singleKjoreotyType,
  singleOblattype,
  confirmKjoretoyliste,
  oblater
}) => {
  const isReqiredFieldsFilledIn =
    singleKjoretoy === "" || singleBeskrivelse === "" || singleKjoreotyType === "" || singleOblattype === "";

  const [duplicateExist, setDuplicateExist] = useState();

  const validateRegnummer = regnummer => {
    setDuplicateExist(
      oblater.find(
        oblat =>
          oblat.kjoretoy.registreringsnummer.toUpperCase() === regnummer.toUpperCase() &&
          oblat.kjoretoy.status === OBLAT_STATUS.AKTIV
      )
    );
  };

  return (
    <div className="oblatregistrering-container">
      <h3>Registrer enkeltoblat</h3>

      <div className="oblatregistrering-body">
        <div>
          <FormGroup>
            <Label for="kjoretoy">Registreringsnummer</Label>
            <Input
              type="text"
              name="kjoretoy"
              id="kjoretoy"
              placeholder="Kjøretøy"
              onChange={e => {
                onChange("singleKjoretoy", e.target.value);
                validateRegnummer(e.target.value);
              }}
              value={singleKjoretoy}
            />
          </FormGroup>
          <p>Kjøretøytype</p>
          <ButtonGroup>
            <Button
              color="primary"
              onClick={() => onChange("singleKjoreotyType", "1")}
              active={singleKjoreotyType === "1"}
            >
              Lett
            </Button>
            <Button
              color="primary"
              onClick={() => onChange("singleKjoreotyType", "2")}
              active={singleKjoreotyType === "2"}
            >
              Tung
            </Button>
          </ButtonGroup>
          <FormGroup>
            <Label for="beskrivelse"> Eier / Beskrivelse </Label>
            <Input
              type="textarea"
              name="beskrivelse"
              id="beskrivelse"
              onChange={e => onChange("singleBeskrivelse", e.target.value)}
              value={singleBeskrivelse}
            />
          </FormGroup>
        </div>
        <div>
          <p>Oblattype</p>
          <ButtonGroup>
            <Button
              color="primary"
              onClick={() => onChange("singleOblattype", "DIPLOMAT")}
              active={singleOblattype === "DIPLOMAT"}
            >
              Diplomatbil
            </Button>
            <Button
              color="primary"
              onClick={() => onChange("singleOblattype", "UTRYKNING")}
              active={singleOblattype === "UTRYKNING"}
            >
              Utrykkning
            </Button>
          </ButtonGroup>
        </div>
        <div className="oblatregistrering-body-submit">
          {isReqiredFieldsFilledIn && <Alert color="info">*Fyll inn feltene for å registrere oblat</Alert>}
          {duplicateExist && <Alert color="warning">{singleKjoretoy}, er allerede registrert</Alert>}
          <Button color="success" disabled={isReqiredFieldsFilledIn} onClick={confirmKjoretoyliste}>
            Lagre
          </Button>
        </div>
      </div>
    </div>
  );
};

EnkeltkjopForm.propTypes = {
  oblattype: PropTypes.shape({}).isRequired,
  confirmKjoretoyliste: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  singleKjoretoy: PropTypes.string.isRequired,
  singleBeskrivelse: PropTypes.string.isRequired,
  singleKjoreotyType: PropTypes.string.isRequired,
  singleOblattype: PropTypes.string.isRequired,
  oblater: PropTypes.arrayOf(PropTypes.shape({}))
};

export default EnkeltkjopForm;
