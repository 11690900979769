import Api from "../api";
import { toastSuccess, toastError, toastWarning } from "./toast";

// types
const GET_KJORETOY_OBLATER = "GET_KJORETOY_OBLATER";
const GET_KJORETOY_OBLATER_REQUEST = "GET_KJORETOY_OBLATER_REQUEST";
const SET_KJORETOY_FILTERS = "SET_KJORETOY_FILTERS";

export default function reducer(
  state = {
    oblater: [],
    filters: {
      kjoretoyInput: "",
      oblatTableFilters: [
        { id: "oblattype", value: "" },
        { id: "erOverfortString", value: "" },
        { id: "gyldigFra", value: "" },
        { id: "gyldigTil", value: "" },
        { id: "kjoretoytype", value: "" },
        { id: "kjoretoy.registreringsnummer", value: "" },
        { id: "kjoretoy.beskrivelse", value: "" },
        { id: "status", value: "" },
        { id: "kjopsinfo.kjopsdato", value: "" },
        { id: "kjopsinfo.betalingsform", value: "" },
        { id: "faktura.fakturaNummer", value: "" },
        { id: "kjopsinfo.price", value: "" }
      ]
    }
  },
  action = {}
) {
  if (action.type === GET_KJORETOY_OBLATER) {
    return {
      ...state,
      oblater: action.payload
    };
  } else if (action.type === GET_KJORETOY_OBLATER_REQUEST) {
    return {
      ...state,
      oblater: []
    };
  } else if (action.type === SET_KJORETOY_FILTERS) {
    return {
      ...state,
      filters: action.payload
    };
  }
  return state;
}

export const resetOblaterForKjoretoy = () => {
  return { type: GET_KJORETOY_OBLATER_REQUEST };
};

function getOblaterForKjoretoySuccess(payload) {
  return { type: GET_KJORETOY_OBLATER, payload };
}

// function getOblaterForKjoretoyError(payload) {
//   return { type: GET_KUNDE_ERROR, payload };
// }

export function getOblaterForKjoretoy(registreringsnummer, kunInnevaerendeSesong) {
  return dispatch =>
    Api.getKjoreotyMedOblater(registreringsnummer, kunInnevaerendeSesong)
      .then(response => {
        if (response.data.result && response.data.result.length === 0)
          dispatch(toastWarning("Ingen oblater finnes for dette kjøretøyet"));
        dispatch(getOblaterForKjoretoySuccess(response.data.result));
        return Promise.resolve(response.data.result);
      })
      .catch(() => {
        // dispatch(toastError("Kunne ikke finne kjøretøy"));
        return Promise.reject();
      });
}

const setKjoretoyOblatFilterAction = payload => ({
  type: SET_KJORETOY_FILTERS,
  payload
});

export const setKjoretoyOblatFilter = filters => dispatch => {
  dispatch(setKjoretoyOblatFilterAction(filters));
};

export const getKjoreotySelector = state => {
  return state.kjoretoyOblater.oblater;
};

export const getKjoreotyFiltersSelector = state => state.kjoretoyOblater.filters;
