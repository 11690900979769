import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import sitePaths from "../navigation/sitePaths";
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { erBrukerAdmin, erLesetilgangbruker, erBrukerServiceAvdelingBruker } from "../../utils/AuthorizationUtils";
import { useAuth } from "react-oidc-context";
import "./Login.css";
import { oidcBaseUrl } from "auth/oidc";

export default function Login() {
  const history = useHistory();
  const location = useLocation<{ from: string }>();
  const [showError, setShowError] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    if (erBrukerAdmin()) return history.replace(location.state?.from ?? sitePaths.kunderPage);
    if (erBrukerServiceAvdelingBruker()) return history.replace(sitePaths.kunderPage);
    if (erLesetilgangbruker()) return history.replace(sitePaths.kjoretoy);
  }, [auth.isAuthenticated, history, location.state]);

  useEffect(() => {
    if (auth?.error) {
      setShowError(true);

      // Logout user via iframe and show error
      setShowError(true);
      const ifrm = document.createElement("iframe");
      ifrm.height = "0";
      ifrm.width = "0";
      ifrm.hidden = true;
      ifrm.src = `${oidcBaseUrl}logout`;
      ifrm.onload = function () {
        window.document.body.removeChild(ifrm);
      };
      window.document.body.appendChild(ifrm);
    }
  }, [auth?.error]);

  return (
    <>
      <section className="login-page">
        <div className="bg-white p-5">
          <h1>Logg inn her</h1>
          <Button
            disabled={auth.isLoading}
            className="w-100 mt-5 ml-auto"
            color="primary"
            size="md"
            onClick={() => {
              auth.signinRedirect();
            }}
          >
            {auth.isLoading ? (
              <>
                Vennligst vent <span role="status" className="spinner-border spinner-border-sm text-light" />
              </>
            ) : (
              "Logg inn"
            )}
          </Button>
        </div>
      </section>
      <Modal isOpen={showError}>
        <ModalHeader>Oijda, her har det skjedd noe rart!</ModalHeader>
        <ModalBody>
          <Alert color="danger" className="text-center">
            {auth.error?.message}
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className=""
            onClick={() => {
              setShowError(false);
            }}
          >
            Ok og lukk
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
