import { Component } from "react";
import DatePicker from "react-datepicker";
import nb from "date-fns/locale/nb";
import { parseISO, isValid, format, endOfDay } from "date-fns";
import { Systemkonfigurasjon } from "api/interfaces";
import { Alert } from "reactstrap";

interface KonfigurasjonFormProps {
  konfigurasjon: Systemkonfigurasjon;
  updateSystemkonfigurasjon: Function;
}

interface State {
  verdi: string;
}

class KonfigurasjonForm extends Component<KonfigurasjonFormProps, State> {
  public constructor(props) {
    super(props);
    this.state = {
      verdi: this.props.konfigurasjon.verdi
    };
  }

  componentDidUpdate = prevprops => {
    if (prevprops.konfigurasjon.verdi !== this.props.konfigurasjon.verdi)
      this.setState({ verdi: this.props.konfigurasjon.verdi });
  };

  handleUpdate = () => {
    const { konfigurasjon, updateSystemkonfigurasjon } = this.props;
    const { verdi } = this.state;

    updateSystemkonfigurasjon(konfigurasjon.id, {
      navn: konfigurasjon.navn,
      verdi,
      kategori: konfigurasjon.kategori
    });
  };

  render() {
    const { konfigurasjon } = this.props;
    const { verdi } = this.state;
    let dateValue = parseISO(verdi);
    const erDatoKonfigurasjon = isValid(dateValue);
    let formattedDateValue = "";
    if (erDatoKonfigurasjon) {
      formattedDateValue = format(dateValue, "dd.MM.yyyy - HH:mm", { locale: nb });
      if (konfigurasjon.navn === "sesongSlutt" || konfigurasjon.navn === "salgSlutt") {
        formattedDateValue = format(endOfDay(dateValue), "dd.MM.yyyy - HH:mm", { locale: nb });
      }
    }

    return (
      <div className="konfigurasjon-edit-form-container">
        <div className="konfigurasjon-edit-form">
          <span className="konfigurasjon-label">{`${konfigurasjon.navn} - (${konfigurasjon.kategori})`}</span>
          {!erDatoKonfigurasjon && (
            <input
              id={`${konfigurasjon.verdi}${konfigurasjon.id}`}
              className="konfigurasjon-input"
              type="string"
              onChange={e => this.setState({ verdi: e.target.value })}
              value={verdi}
            />
          )}

          {erDatoKonfigurasjon && (
            <>
              <DatePicker
                wrapperClassName="konfigurasjon-input-dato "
                className="konfigurasjon-input"
                selected={dateValue}
                dateFormat="dd.MM.yyyy"
                timeFormat="p"
                onChange={dato => {
                  this.setState({ verdi: dato.toISOString() });
                }}
              />
              <span className="konfigurasjon-formatted-date">{formattedDateValue}</span>
            </>
          )}
          <button className="konfigurasjon-button" onClick={this.handleUpdate}>
            Lagre
          </button>
        </div>

        {erDatoKonfigurasjon && (
          <Alert color="info" className="konfigurasjon-info">
            {konfigurasjon.navn === "sesongStart" && (
              <>
                Velg <b>dato</b> for sesongstart, trykk så på «<b>Lagre</b>» for at endringene skal ta effekt.
                <br />
                Tidspunktet for <b>sesongsstart</b> blir da <b>00:00:00</b> den valgte datoen
              </>
            )}
            {konfigurasjon.navn === "sesongSlutt" && (
              <>
                Velg <b>dato</b> for sesongslutt, trykk så på «<b>Lagre</b>» for at endringene skal ta effekt.
                <br />
                Tidspunktet for <b>sesongslutt</b> blir da <b>23:59:59</b> den valgte datoen
              </>
            )}
            {konfigurasjon.navn === "salgStart" && (
              <>
                Velg <b>dato</b> for sesongstart, trykk så på «<b>Lagre</b>» for at endringene skal ta effekt.
                <br />
                Tidspunktet for <b>salgstart</b> blir da <b>00:00:00</b> den valgte datoen
              </>
            )}
            {konfigurasjon.navn === "salgSlutt" && (
              <>
                Velg <b>dato</b> for sesongstart, trykk så på «<b>Lagre</b>» for at endringene skal ta effekt.
                <br />
                Tidspunktet for <b>salgslutt</b> blir da <b>23:59:59</b> den valgte datoen
              </>
            )}
          </Alert>
        )}
      </div>
    );
  }
}

export default KonfigurasjonForm;
