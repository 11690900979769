import filter from "lodash/filter";
import Api from "../api";
import { toastError, toastSuccess } from "./toast";

// types
const POST_KJORETOYLISTE_SUCCESS = "POST_KJORETOYLISTE_SUCCESS";
const OPPDATER_KJORETOYLISTE_TYPE = "OPPDATER_KJORETOYLISTE_TYPE";
const SLETT_KJORETOY_FRA_LISTE = "SLETT_KJORETOY_FRA_LISTE";
const RESET_KJORETOYLISTE = "RESET_KJORETOYLISTE";
const GET_ADMIN_OBLATER = "GET_ADMIN_OBLATER";

export const opdaterKjoreotyListeType = oblatType => dispatch =>
  dispatch({ type: OPPDATER_KJORETOYLISTE_TYPE, payload: oblatType });

export const slettKjoretoyoyFraListe = kjoretoyId => dispatch =>
  dispatch({ type: SLETT_KJORETOY_FRA_LISTE, payload: kjoretoyId });

export const resetKjoretoyliste = kjoretoyId => dispatch =>
  dispatch({ type: RESET_KJORETOYLISTE, payload: kjoretoyId });

export default function reducer(
  state = {
    kjoretoyliste: [],
    oblatliste: [],
    oblattype: ""
  },
  action = {}
) {
  if (action.type === POST_KJORETOYLISTE_SUCCESS) {
    return {
      ...state,
      kjoretoyliste: action.payload,
      oblattype: ""
    };
  }
  if (action.type === SLETT_KJORETOY_FRA_LISTE) {
    const k = filter(state.kjoretoyliste, i => i.id !== action.payload);
    return {
      ...state,
      kjoretoyliste: k
    };
  }
  if (action.type === OPPDATER_KJORETOYLISTE_TYPE) {
    return {
      ...state,
      oblattype: action.payload
    };
  }
  if (action.type === RESET_KJORETOYLISTE) {
    return {
      ...state,
      kjoretoyliste: [],
      oblattype: ""
    };
  }
  if (action.type === GET_ADMIN_OBLATER) {
    return {
      ...state,
      oblatliste: action.payload,
      oblattype: ""
    };
  }
  return state;
}

const postKjoretoylisteSuccess = payload => {
  return { type: POST_KJORETOYLISTE_SUCCESS, payload };
};

export const postKjoretoyliste = (brukerId, fil) => dispatch => {
  const data = new FormData();
  data.append("file", fil);
  data.append("name", "name");
  return Api.postKjoretoyliste(brukerId, data)
    .then(response => dispatch(postKjoretoylisteSuccess(response.data.result)))
    .catch(err => dispatch(toastError(err)));
};

const getOblaterForAdminSuccess = payload => ({
  type: GET_ADMIN_OBLATER,
  payload
});

export function getOblaterForAdmin(brukerId, kunInnevaerendeSesong) {
  return dispatch =>
    Api.getOblaterForKunde(brukerId, kunInnevaerendeSesong).then(response => {
      dispatch(getOblaterForAdminSuccess(response.data.result));
    });
}

export const confirmKjoretoyliste = (brukerId, kjoretoyliste, oblattype) => dispatch => {
  return Api.confirmKjoretoyliste(brukerId, kjoretoyliste, oblattype).then(() => {
    dispatch(getOblaterForAdmin(brukerId));
    dispatch(resetKjoretoyliste());
    dispatch(toastSuccess("Oblater er lagt til"));
  });
};

export const getKjoretoylisteSelector = state => state.oblatKjop.kjoretoyliste;
export const getAdminOblaterSelector = state => state.oblatKjop.oblatliste;
