import React, { Component } from "react";
import PropTypes from "prop-types";
import Aktivering from "./Aktivering";
import Api from "../../api/index";

class AktiveringContainer extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        key: PropTypes.string
      })
    }).isRequired
  };

  state = {
    aktiveringResult: ""
  };

  componentDidMount() {
    Api.activateUser(this.props.match.params.key)
      .then(() =>
        this.setState({
          aktiveringResult: "Din konto er aktivert og du kan logge deg inn"
        })
      )
      .catch(() => {
        this.setState({
          aktiveringResult: "Kunne ikke aktivere brukerkonto"
        });
      });
  }

  render() {
    return <Aktivering message={this.state.aktiveringResult} />;
  }
}

export default AktiveringContainer;
