import { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  FormText
} from "reactstrap";
import { OBLAT_STATUS, PeriodeId } from "constants/Constants";
import DatePicker from "react-datepicker";
import OblatLog from "./OblatLog";
import {
  kansellerOblatForKunde as kansellerOblatForKundeAction,
  hentOblatLogg as hentOblatLoggKundeAction,
  getOblaterForKunde as getOblaterForKundeAction,
  kalkulerRefusjonsbelopForOblat as kalkulerRefusjonsbelopForOblatAction
} from "../../state/kunde";
import { Oblat, Oblattype } from "api/interfaces";
import { ReactComponent as InfoIcon } from "../../images/Advarselsirkel.svg";

import "./oblatActions.css";
import { getOblatTypeByPeriodeIdAndKjoretoytypeId } from "state/oblattyper";

interface RefunderOblatProps {
  showOnlyThisSeason: boolean;
  selectedBrukerId: string;
  oblat: Oblat;
  hentOblatLogg: Function;
  kansellerOblatForKunde: Function;
  getOblaterForKunde: Function;
  kalkulerRefusjonsbelopForOblat: Function;
  editable: boolean;
  oblattypeDags?: Oblattype;
}

interface State {
  cancelModalOpen: boolean;
  laster: boolean;
  result: Object;
  logg: Array<any>;
  kanselleringsfeil: string;
  erRefundert: boolean;
  dato: Date;
  kommentar: string;
  belop?: string;
  kalkulertBelop?: string;
  kalkulerererRefusjonBelop: boolean;
  error: {
    refusjonsBelop?: boolean;
  };
}

export const invalidBelop = (belop: number, oblatPris: number, oblattype?: Oblattype) => {
  if (belop < 1 || belop > oblatPris || belop < oblattype?.pris) return true;
  return false;
};

class RefunderOblat extends Component<RefunderOblatProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      cancelModalOpen: false,
      laster: false,
      result: {},
      logg: [],
      kanselleringsfeil: "",
      erRefundert: false,
      dato: new Date(),
      kommentar: "",
      belop: null,
      kalkulerererRefusjonBelop: false,
      error: {
        refusjonsBelop: false
      }
    };
  }

  hentOblatLoggForOblat = async () => {
    const { selectedBrukerId, oblat, hentOblatLogg } = this.props;
    try {
      this.setState({ laster: true });
      const result = await hentOblatLogg(selectedBrukerId, oblat.id);
      this.setState({
        logg: result && result.data ? result.data.result : []
      });
    } finally {
      this.setState({ laster: false });
    }
  };

  handleKansellerOblat = async (resterendeRefusjon: boolean = false) => {
    const { selectedBrukerId, oblat, kansellerOblatForKunde, editable } = this.props;
    const { kommentar, belop, kalkulertBelop, dato } = this.state;
    if (!editable) return;

    const erBelopAnnetEnnKalkulert = belop != kalkulertBelop;

    try {
      this.setState({
        laster: true,
        kanselleringsfeil: ""
      });
      const res = await kansellerOblatForKunde(
        selectedBrukerId,
        oblat.kjopsinfo.varekjopId,
        oblat.id,
        kommentar,
        resterendeRefusjon,
        erBelopAnnetEnnKalkulert ? undefined : dato.toUTCString(),
        erBelopAnnetEnnKalkulert ? belop : undefined
      );
      if (res.data.errorMessage || res.data.errorkode) {
        this.setState({
          laster: false,
          kanselleringsfeil: res.data.errorMessage || "Noe galt skjedde"
        });
      } else {
        this.setState({
          erRefundert: true,
          result: res && res.data ? res.data.result : [],
          kommentar: ""
        });
      }
      await this.hentOblatLoggForOblat();
    } catch (err) {
      this.setState({
        kanselleringsfeil: `Noe gikk galt ved kansellering av oblat: \n ${
          err.response && err.response.data ? err.response.data.errorMessage : err
        }`
      });
    } finally {
      this.setState({
        laster: false
      });
    }
  };

  handleKalkulerBelop = async () => {
    const { selectedBrukerId, oblat, kalkulerRefusjonsbelopForOblat } = this.props;
    const { dato } = this.state;
    try {
      this.setState({ kalkulerererRefusjonBelop: true });
      const res = await kalkulerRefusjonsbelopForOblat(
        selectedBrukerId,
        oblat.kjopsinfo.varekjopId,
        oblat.id,
        dato.toUTCString()
      );
      this.setState({
        kalkulertBelop: res.data.result,
        belop: res.data.result,
        error: { refusjonsBelop: invalidBelop(Number(res.data.result), oblat.kjopsinfo.price) }
      });
    } finally {
      this.setState({ kalkulerererRefusjonBelop: false });
    }
  };

  toggleOpen = () => {
    const { cancelModalOpen } = this.state;
    const { selectedBrukerId, getOblaterForKunde, showOnlyThisSeason } = this.props;
    this.setState({
      cancelModalOpen: !cancelModalOpen,
      kanselleringsfeil: "",
      result: {}
    });

    if (!cancelModalOpen) {
      this.hentOblatLoggForOblat();
    } else {
      getOblaterForKunde(selectedBrukerId, showOnlyThisSeason);
    }
  };

  render() {
    const { oblat, editable, oblattypeDags } = this.props;
    const { cancelModalOpen, laster, logg, kanselleringsfeil, kommentar, erRefundert, dato, belop, error } = this.state;
    return (
      <>
        <Button
          disabled={!editable}
          className="oblat-action-button"
          color="warning"
          block
          size="sm"
          onClick={this.toggleOpen}
        >
          {oblat.statusId === OBLAT_STATUS.REFUNDERT || erRefundert ? "Refundert" : "Refunder"}
        </Button>
        <Modal className="oblatdetaljer" isOpen={cancelModalOpen} toggle={this.toggleOpen}>
          <ModalHeader toggle={this.toggleOpen}>Refundere oblat</ModalHeader>
          <ModalBody>
            {cancelModalOpen && (
              <div className="oblataction-sections">
                <Alert className="oblatdetaljer-section" color="info">
                  {oblat.statusId === OBLAT_STATUS.REFUNDERT || erRefundert ? (
                    <>
                      <h4>Oblatet er refundert </h4>
                      <p>Oblatet er allerede refundert, se oblathistorikk nedenfor.</p>
                    </>
                  ) : (
                    <>
                      <h4>Ønsker du å refundere dette oblatet? </h4>
                      <p>
                        Velg mellom å refundere hele beløpet (kr {oblat.kjopsinfo.price}), eller delvis refundering.
                        Dette kan gjøres selv i denne dialogen lenger ned ved å klikke på hendholdsvis «Full refusjon,
                        eller trykke «Kalkuler» for delvis refundering.
                      </p>
                    </>
                  )}
                </Alert>
                <OblatLog oblat={oblat} logg={logg} laster={laster} />
                <div className="oblatdetaljer-section textarea-section">
                  <strong>Kommentar</strong>
                  <textarea
                    placeholder="Skriv en kommentar til refunderingen"
                    rows={5}
                    value={kommentar}
                    onChange={e => this.setState({ kommentar: e.target.value })}
                  />
                </div>

                {kanselleringsfeil !== "" && (
                  <Alert className="oblatdetaljer-section" color="danger">
                    {kanselleringsfeil}
                  </Alert>
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="partial-refund-button">
              {!(oblat.statusId === OBLAT_STATUS.REFUNDERT || erRefundert) && (
                <>
                  <div>
                    <Label htmlFor="datepicker-refund">
                      <strong>Refusjons fra dato:</strong>
                    </Label>
                    <div className="flexcolumn">
                      <div className="date-info">
                        <DatePicker
                          id="datepicker-refund"
                          selected={dato}
                          dateFormat="dd.MM.yyyy"
                          onChange={(date: Date) => this.setState({ dato: date })}
                          customInput={<Input />}
                        />
                        <div className="tooltipcontainer">
                          <InfoIcon id="infoIcon" />
                          <div className="tooltipinfo">
                            <p>Kalkulering av delvis beløp er slik.</p>
                            <p>
                              <strong>Sesongoblat: </strong>Pris på oblatet / Antall dager i hele sesongen * Gjenværende
                              dager i sesongen
                            </p>
                            <p>
                              <strong>Månedsoblat: </strong> Pris på oblatet / Antall dager oblatet har vært gyldig *
                              Gjenværende dager på oblatet
                            </p>
                            <p>
                              <strong>Dagsoblat:</strong> Full refusjon alltid
                            </p>
                            <p>
                              Dersom beregningen ikke passer behovet for refusjon, kan feltet «Sum» overskrives med
                              ønsket beløp som skal refunderes. For full refusjon trykk &quot;Full refusjon&quot;
                            </p>
                          </div>
                        </div>
                      </div>
                      <Button color="success" onClick={() => this.handleKalkulerBelop()}>
                        Kalkuler
                      </Button>
                    </div>
                  </div>
                  {belop !== null && (
                    <>
                      <div className="flexcolumn">
                        <FormGroup>
                          <Label htmlFor="belop">
                            <strong>Sum:</strong>
                          </Label>
                          <Input
                            invalid={error.refusjonsBelop}
                            name="belop"
                            value={belop}
                            onChange={e => {
                              const b = e.target.value.replace(/\D/, "");
                              this.setState({
                                belop: b,
                                error: { refusjonsBelop: invalidBelop(Number(b), oblat.kjopsinfo.price, oblattypeDags) }
                              });
                            }}
                          />
                          <FormFeedback>{`Refusjonsbeløp må være mellom ${
                            oblattypeDags?.pris ? `Prisen for et dagsoblat (kr ${oblattypeDags?.pris})` : 1
                          } og full pris (kr ${oblat.kjopsinfo.price})`}</FormFeedback>
                          <FormText>
                            Skriv inn annet beløp dersom beregningen ikke passer behovet for refusjon,
                          </FormText>
                        </FormGroup>

                        <Button
                          disabled={!editable || erRefundert || laster || error.refusjonsBelop}
                          color="primary"
                          onClick={() => this.handleKansellerOblat(true)}
                        >
                          {oblat.statusId === OBLAT_STATUS.REFUNDERT || erRefundert ? "Refundert" : "Delvis refusjon"}
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
              <Button
                id="fullRefund-button"
                disabled={!editable || erRefundert || laster}
                color="warning"
                onClick={() => this.handleKansellerOblat()}
              >
                {oblat.statusId === OBLAT_STATUS.REFUNDERT || erRefundert ? "Refundert" : "Full refusjon"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    oblattypeDags: getOblatTypeByPeriodeIdAndKjoretoytypeId(
      state,
      PeriodeId.DAG,
      ownProps.oblat?.kjoretoy?.kjoretoytypeId
    )
  };
};

const mapDispatchToProps = dispatch => ({
  hentOblatLogg: (brukerId, oblatId) => hentOblatLoggKundeAction(brukerId, oblatId),
  kansellerOblatForKunde: (kundeId, varekjopId, oblatId, kommentar, resterendeRefusjon, refusjonsdato, belop) =>
    kansellerOblatForKundeAction(kundeId, varekjopId, oblatId, kommentar, resterendeRefusjon, refusjonsdato, belop),
  kalkulerRefusjonsbelopForOblat: (brukerId, varekjopId, oblatId, refusjonsdato) =>
    kalkulerRefusjonsbelopForOblatAction(brukerId, varekjopId, oblatId, refusjonsdato),
  getOblaterForKunde: (brukerId, kunInnevaerendeSesong) =>
    dispatch(getOblaterForKundeAction(brukerId, kunInnevaerendeSesong))
});

export default connect(mapStateToProps, mapDispatchToProps)(RefunderOblat);
