import { isValid, parseISO } from "date-fns";
import { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "reactstrap";

interface NyKonfigurasjonFormProps {
  postSystemkonfigurasjon: Function;
}

type KonfigurasjonType = "text" | "date";

interface State {
  type: KonfigurasjonType;
  verdi: string;
  navn: string;
  kategori: string;
}

class NyKonfigurasjonForm extends Component<NyKonfigurasjonFormProps, State> {
  public constructor(props) {
    super(props);
    this.state = {
      type: "text",
      verdi: "",
      navn: "",
      kategori: ""
    };
  }

  handleSave = () => {
    const { verdi, kategori, navn } = this.state;
    this.props.postSystemkonfigurasjon(navn, kategori, verdi).then(() =>
      this.setState({
        type: "text",
        verdi: "",
        navn: "",
        kategori: ""
      })
    );
  };

  render() {
    const { navn, verdi, kategori, type } = this.state;
    const parsedDate = type === "date" ? parseISO(verdi) : undefined;
    return (
      <div className="">
        <h3>Legg til systemkonfigurasjon</h3>

        <input
          className="konfigurasjon-input"
          placeholder="Navn"
          type="text"
          value={navn}
          onChange={e => this.setState({ navn: e.target.value })}
        />

        <div>
          <select
            className="konfigurasjon-input"
            placeholder="Type"
            value={type}
            onChange={e => this.setState({ verdi: "", type: e.target.value as KonfigurasjonType })}
          >
            <option value="" disabled hidden>
              Velg type
            </option>
            <option value="text">Tekst</option>
            <option value="date">Dato</option>
          </select>
          <br />
          {type === "text" && (
            <input
              className="konfigurasjon-input"
              placeholder="Verdi"
              type="text"
              value={verdi}
              onChange={e => this.setState({ verdi: e.target.value })}
            />
          )}
          {type === "date" && (
            <>
              <DatePicker
                className="konfigurasjon-input"
                selected={isValid(parsedDate) ? parsedDate : new Date()}
                dateFormat="dd.MM.yyyy - HH:mm"
                locale="nb"
                timeFormat="p"
                showTimeSelect
                onChange={dato => this.setState({ verdi: dato.toISOString() })}
              />
              <Alert color="info" className="m-1">
                Velg <b>dato</b> og <b>klokkeslett</b>
              </Alert>
            </>
          )}
        </div>
        <select
          className="konfigurasjon-input"
          placeholder="Kategori"
          value={kategori}
          onChange={e => this.setState({ kategori: e.target.value })}
        >
          <option value="" disabled hidden>
            Velg kategori
          </option>
          <option value="system">System</option>
          <option value="annet">Annet</option>
        </select>
        <button className="konfigurasjon-button" onClick={this.handleSave}>
          Lagre
        </button>
      </div>
    );
  }
}

export default NyKonfigurasjonForm;
