import { Component } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import moment from "moment";
import { connect } from "react-redux";
import ReactTable from "react-table";
import KundeInfo from "./KundeInfo";

import OblatActionsCol from "./OblatActionsCol";
import sitePaths from "../navigation/sitePaths";
import { getBruker as getBrukerAction, getBrukerSelector } from "../../state/bruker";
import { getOblattyper as getOblattyperAction, getOblattyperSelector } from "../../state/oblattyper";
import { erBrukerAdmin, erBrukerServiceAvdelingBruker } from "../../utils/AuthorizationUtils";
import {
  getKundedata as getKundedataAction,
  updateKunde as updateKundeAction,
  getOblaterForKunde as getOblaterForKundeAction,
  getKjoretoyForKunde as getKjoretoyForKundeAction,
  kansellerOblatForKunde as kansellerOblatForKundeAction,
  hentOblatLogg as hentOblatLoggKundeAction,
  hentKjoretoyForBruker as hentKjoretoyForBrukerAction,
  hentKjoretoyTyper as hentKjoretoyTyperAction,
  opprettKjoretoyForBruker as opprettKjoretoyForBrukerAction,
  endreOblatForBruker as endreOblatForBrukerAction,
  getKundeOblaterSelector,
  getKundeErrorSelector,
  getOblaterSelector,
  getKjoretoySelector,
  getKundeSelector,
  getKundetyper,
  getKundetyperSelector
} from "../../state/kunde";
import { filterIncludeCaseInsensitive } from "../../utils/Helperfunctions";
import "./Kunde.css";

export const onBrukerSelected = (bruker, history) => {
  history.push({
    pathname: sitePaths.userSelectedWithKey(bruker.brukerId),
    state: { bruker }
  });
};

export class KundeContainer extends Component {
  static propTypes = {
    getKundedata: PropTypes.func.isRequired,
    updateKunde: PropTypes.func.isRequired,
    getKjoretoyForKunde: PropTypes.func.isRequired,
    getOblaterForKunde: PropTypes.func.isRequired,
    getOblattyper: PropTypes.func.isRequired,
    kunde: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    kundeError: PropTypes.string.isRequired,
    oblater: PropTypes.shape().isRequired,
    filters: PropTypes.shape({}).isRequired,
    onKundeFilterChange: PropTypes.func,
    onKundeDeleted: PropTypes.func.isRequired
  };

  static defaultProps = {
    onKundeFilterChange: () => {}
  };

  state = {
    selectedBrukerId: "",
    brukerrolleErAdmin: erBrukerAdmin(),
    brukerrolleServiceAvdelingBruker: erBrukerServiceAvdelingBruker(),
    kunInnevaerendeSesong: true,
    loadingKunde: true
  };

  columns = [
    {
      Header: "Oblattype",
      accessor: "oblattype",
      filterMethod: filterIncludeCaseInsensitive,
      minWidth: 250
    },
    {
      Header: "Status",
      accessor: "status",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Kjoretoytype",
      accessor: "kjoretoytype",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Registreringsnummer",
      accessor: "kjoretoy.registreringsnummer",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Beskrivelse",
      accessor: "kjoretoy.beskrivelse",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Kjøpsdato",
      accessor: "kjopsinfo.kjopsdato",
      filterMethod: this.filterDate,
      minWidth: 140,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 140 }}
        />
      )
    },
    {
      Header: "Betalingsdato",
      accessor: "kjopsinfo.betalingsdato",
      filterMethod: this.filterDate,
      minWidth: 140,
      Cell: row => (
        <span>{row.value ? moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm") : "Avventer betaling"}</span>
      ),
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 140 }}
        />
      )
    },
    {
      Header: "Gyldig fra",
      accessor: "gyldigFra",
      filterMethod: this.filterDate,
      minWidth: 140,
      // filterable: false,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 140 }}
        />
      )
    },
    {
      Header: "Gyldig til",
      accessor: "gyldigTil",
      filterMethod: this.filterDate,
      minWidth: 140,
      // filterable: false,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 140 }}
        />
      )
    },
    {
      Header: "Detaljer",
      accessor: "",
      sortable: false,
      filterable: false,
      width: 250,
      Cell: ({ original }) => {
        return (
          <OblatActionsCol
            showOnlyThisSeason={this.state.kunInnevaerendeSesong}
            selectedBrukerId={this.state.selectedBrukerId}
            oblat={original}
            brukerrolleErAdmin={this.state.brukerrolleErAdmin}
            brukerrolleServiceAvdelingBruker={this.state.brukerrolleServiceAvdelingBruker}
          />
        );
      }
    }
  ];

  componentDidMount() {
    const { getOblattyper } = this.props;
    this.updateBrukerView();
    getOblattyper();
  }

  componentDidUpdate = () => {
    this.updateBrukerView();
  };

  updateBrukerView = () => {
    const { location, getKundedata, getOblaterForKunde, getKjoretoyForKunde } = this.props;
    const { selectedBrukerId, kunInnevaerendeSesong } = this.state;

    const brukerId = location.state && location.state.bruker ? location.state.bruker.brukerId : "";

    if (brukerId !== "" && selectedBrukerId !== brukerId) {
      getKundedata(brukerId);
      this.setState({ loadingKunde: true });
      getOblaterForKunde(brukerId, kunInnevaerendeSesong).then(() => this.setState({ loadingKunde: false }));
      getKjoretoyForKunde(brukerId);
      this.setState({ selectedBrukerId: brukerId });
    }
  };

  onKunInnevaerendeSesongChangeClick = () => {
    const { location, getOblaterForKunde } = this.props;

    const newState = !this.state.kunInnevaerendeSesong;
    this.setState({
      kunInnevaerendeSesong: newState
    });

    const brukerId = location.state && location.state.bruker ? location.state.bruker.brukerId : "";

    getOblaterForKunde(brukerId, newState);
  };

  render() {
    const { kunde, kundeError, updateKunde, oblater, onKundeFilterChange, filters, onKundeDeleted } = this.props;

    const { kunInnevaerendeSesong, loadingKunde } = this.state;

    const data = oblater
      ? map(oblater, item => {
          return {
            ...item,
            kjoretoytype: item.kjoretoy.kjoretoytypeId === 1 ? "Lett" : "Tung"
          };
        })
      : [];

    return (
      <>
        <section>
          <div className="kun-innevaerende-sesong-checkbox">
            <input
              type="checkbox"
              checked={kunInnevaerendeSesong}
              onChange={() => this.onKunInnevaerendeSesongChangeClick()}
            />{" "}
            Kun inneværende sesong
          </div>
          {kunde && kunde.brukerId && (
            <ReactTable
              className="-striped -highlight"
              data={data}
              columns={this.columns}
              defaultPageSize={5}
              filterable
              noDataText={loadingKunde ? "" : "ingen data"}
              defaultFiltered={filters}
              onFilteredChange={onKundeFilterChange}
              loading={loadingKunde}
              loadingText="Henter data..."
            />
          )}
        </section>
        <section className="bruker-detaljer">
          {kundeError && kundeError !== "" && <div className="alert alert-warning">{kundeError}</div>}
          {kunde && kunde.brukerId && (
            <KundeInfo kunde={kunde} updateKunde={updateKunde} onDeleteKunde={onKundeDeleted} />
          )}
        </section>
      </>
    );
  }
}

const mapStateToProps = state => ({
  bruker: getBrukerSelector(state),
  kunde: getKundeSelector(state),
  oblater: getOblaterSelector(state),
  kundeError: getKundeErrorSelector(state),
  kundetyper: getKundetyperSelector(state),
  kundeOblater: getKundeOblaterSelector(state),
  kjoretoy: getKjoretoySelector(state),
  oblattyper: getOblattyperSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getBruker: brukerId => dispatch(getBrukerAction(brukerId)),
  updateKunde: (brukerId, payload) => dispatch(updateKundeAction(brukerId, payload)),
  getKundetyper: () => dispatch(getKundetyper()),
  getKundedata: brukerId => dispatch(getKundedataAction(brukerId)),
  getKjoretoyForKunde: brukerId => dispatch(getKjoretoyForKundeAction(brukerId)),
  getOblaterForKunde: (brukerId, kunInnevaerendeSesong) =>
    dispatch(getOblaterForKundeAction(brukerId, kunInnevaerendeSesong)),
  getOblattyper: () => dispatch(getOblattyperAction()),
  kansellerOblatForKunde: (brukerId, varekjopId, oblatId) =>
    dispatch(kansellerOblatForKundeAction(brukerId, varekjopId, oblatId)),
  hentKjoretoyForBruker: brukerId => hentKjoretoyForBrukerAction(brukerId),
  hentKjoretoyTyper: () => hentKjoretoyTyperAction(),
  hentOblatLogg: (brukerId, oblatId) => hentOblatLoggKundeAction(brukerId, oblatId),
  opprettKjoretoyForBruker: (brukerId, regnummer, type) => opprettKjoretoyForBrukerAction(brukerId, regnummer, type),
  endreOblatForBruker: (kundeId, oblatId, kjoretoyId, kommentar) =>
    endreOblatForBrukerAction(kundeId, oblatId, kjoretoyId, kommentar)
});

export default connect(mapStateToProps, mapDispatchToProps)(KundeContainer);
