import React, { useState } from "react";
import { Button, FormGroup, Input, Row, Col, Form } from "reactstrap";
import PropTypes from "prop-types";

function KunderSearchForm({ onSearch = () => null, ...props }) {
  const [searchButtonEnabled, setSearchButtonEnabled] = useState(false);
  const [formValues, setFormValues] = useState({
    epost: "",
    brukerId: ""
  });

  const formChange = e => {
    setSearchButtonEnabled(e.target.value && e.target.value.length >= 3);
    if (e.target.name === "epost") setFormValues({ epost: e.target.value, brukerId: "" });

    if (e.target.name === "brukerId") {
      setFormValues({
        epost: "",
        brukerId: e.target.value
      });
    }
  };

  return (
    <Form
      id="kundesearchform"
      onSubmit={e => {
        e.preventDefault();
        onSearch({
          epost: e.target.epost.value,
          brukerId: e.target.brukerId.value
        });
      }}
      onChange={formChange}
      {...props}
    >
      <FormGroup>
        <Row>
          <Col md={5}>
            <Input type="text" autoFocus name="epost" placeholder="Søk på epost" value={formValues.epost} />
          </Col>
          <Col md={5}>
            <Input name="brukerId" type="text" active placeholder="Søk på brukerid" value={formValues.brukerId} />
          </Col>
          <Col md={2}>
            <Button disabled={!searchButtonEnabled} type="submit" color="primary" size="" style={{ width: "100%" }}>
              Søk
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
}

KunderSearchForm.propTypes = {
  onSearch: PropTypes.func,
  minSearchChars: PropTypes.number
};

export default KunderSearchForm;
