import PropTypes from "prop-types";

export const brukerShape = {
  brukerId: PropTypes.string,
  navn: PropTypes.string,
  epost: PropTypes.string
};

export const rolleShape = {
  rolleId: PropTypes.string,
  rolleNavn: PropTypes.string
};

// eslint-disable-next-line no-unused-vars
const clientInfoShape = {
  id: PropTypes.string,
  navn: PropTypes.string,
  url: PropTypes.string
};

export const serviceShape = {
  serviceId: PropTypes.string,
  serviceNavn: PropTypes.string,
  apiAdresse: PropTypes.string,
  roller: PropTypes.arrayOf(PropTypes.shape(rolleShape))
};

export const prosessrolleRolleShape = {
  serviceId: PropTypes.string,
  prosessrolleId: PropTypes.string,
  prosessrolleRolleId: PropTypes.string,
  prosessrolleRolleNavn: PropTypes.string,
  prosessrolleNavn: PropTypes.string,
  serviceNavn: PropTypes.string
};

export const prosessrolleShape = {
  serviceId: PropTypes.string,
  eierrolleId: PropTypes.string,
  eierrolleNavn: PropTypes.string,
  serviceNavn: PropTypes.string
};

export const errorShape = {
  response: PropTypes.object,
  data: PropTypes.shape({
    errorMessage: PropTypes.string
  }),
  status: PropTypes.number,
  message: PropTypes.string
};

export const locationShape = {
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string
};

export const historyShape = {
  length: PropTypes.number,
  location: PropTypes.shape(locationShape),
  push: PropTypes.func
};
