import { Table } from "reactstrap";
import map from "lodash/map";
import moment from "moment";
import "./oblatActions.css";

interface OblatLogProps {
  oblat: any;
  logg: any[];
  laster: boolean;
}

export default function OblatLog({ oblat, logg, laster }: OblatLogProps): JSX.Element {
  return (
    <>
      <div className="oblatdetaljer-section">
        <strong>Oblat info</strong>
        <div className="oblat-tabell">
          {oblat && (
            <Table>
              <thead>
                <tr>
                  <th>Oblattype</th>
                  <th>Reg.nummer</th>
                  <th>Status</th>
                  <th>Gyldig fra</th>
                  <th>Gyldig til</th>
                  <th>Betalingsform</th>
                  <th>Kjopsdato</th>
                  <th>Pris</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{oblat.oblattype}</td>
                  <td>{oblat.kjoretoy.registreringsnummer}</td>
                  <td>{oblat.status}</td>
                  <td>{moment(oblat.gyldigFra, moment.ISO_8601).format("DD.MM.YY HH:mm")}</td>
                  <td>{moment(oblat.gyldigTil, moment.ISO_8601).format("DD.MM.YY HH:mm")}</td>
                  <td>{oblat.kjopsinfo.betalingsform}</td>
                  <td>{moment(oblat.kjopsinfo.kjopsdato, moment.ISO_8601).format("DD.MM.YY HH:mm")}</td>
                  <td>{oblat.kjopsinfo.price}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      </div>

      <div className="oblatdetaljer-section">
        <strong>Oblathistorikk</strong>
        <div className="oblathistorikk-section">
          {laster ? (
            <p>Henter oblatlogg...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th scope="col" style={{ width: "10%" }}>
                    Dato
                  </th>
                  <th scope="col" style={{ width: "40%" }}>
                    Info
                  </th>
                  <th scope="col" style={{ width: "50%" }}>
                    Kommentar
                  </th>
                </tr>
              </thead>
              <tbody>
                {logg && logg.length > 0 ? (
                  map(logg, item => {
                    const line = item.kommentar ? item.kommentar.split("\nGrunn: ") : null;
                    const info = line ? line[0] : item.systemlogg;
                    const kommentar = line ? line[1] : "";
                    return (
                      <tr>
                        <td>{moment(item.opprettet).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{info}</td>
                        <td>{kommentar || "Ingen kommentar"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}> Ingen data i oblat-logg </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
