import Api from "../api";
import { toastSuccess } from "./toast";

// types
const UPDATE = "brukere/UPDATE";
const CREATE = "brukere/CREATE";

export default function reducer(state = { brukere: undefined }, action = {}) {
  if (action.type === UPDATE) {
    return {
      ...state,
      brukere: action.payload
    };
  } else if (action.type === CREATE) {
    const brukereArray = state.brukere ? [...state.brukere] : [];
    brukereArray.push(action.payload);
    return {
      ...state,
      brukere: brukereArray
    };
  }
  return state;
}

function updateBrukere(payload) {
  return { type: UPDATE, payload };
}

function createBruker(payload) {
  return { type: CREATE, payload };
}

export function getServicebrukere() {
  return dispatch => Api.getServicebrukere().then(response => dispatch(updateBrukere(response.data.result)));
}

export function addBruker(rolle, name, email) {
  return dispatch =>
    Api.addBruker(rolle, name, email).then(response => {
      dispatch(toastSuccess(`La til bruker ${name} med epost ${email}`));
      return response;
    });
}

export const getBrukereSelector = state => state.brukere.brukere;
