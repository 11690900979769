import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import moment from "moment";

const Oblattabell = ({ oblater }) => {
  const filterIncludeCaseInsensitive = (f, row) =>
    f && f.value && row && row[f.id] && row[f.id].toLowerCase().includes(f.value.toLowerCase());

  const filterDate = (f, row) => {
    // moment(f.value.toLowerCase(), "YYYY-mm-dd");
    const dateFilter = moment(f.value);
    const date = moment(row[f.id], moment.ISO_8601);
    return dateFilter.isValid ? date.isSame(dateFilter, "day") : true;
  };

  const columns = [
    {
      Header: "Oblattype",
      accessor: "oblattype",
      filterMethod: filterIncludeCaseInsensitive,
      minWidth: 250
    },
    {
      Header: "Registreringsnummer",
      accessor: "kjoretoy.registreringsnummer",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Kjoretoytype",
      accessor: "kjoretoy.kjoretoytypeId",
      Cell: row => <span>{row.value === 2 ? "Tung(2)" : "Lett(1)"}</span>
    },

    {
      Header: "Beskrivelse",
      accessor: "kjoretoy.beskrivelse",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Status",
      accessor: "status",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "GyldigFra",
      accessor: "gyldigFra",
      filterMethod: filterDate,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 160 }}
        />
      )
    },
    {
      Header: "GyldigTil",
      accessor: "gyldigTil",
      filterMethod: filterDate,
      minWidth: 180,
      // filterable: false,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 160 }}
        />
      )
    },
    {
      Header: "Kjopsdato",
      accessor: "kjopsinfo.kjopsdato",
      filterMethod: filterDate,
      minWidth: 180,
      // filterable: false,
      Cell: row => <span>{moment(row.value, moment.ISO_8601).format("DD.MM.YY HH:mm")}</span>,
      Filter: ({ filter, onChange }) => (
        <input
          type="date"
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          style={{ width: 160 }}
        />
      )
    },
    {
      Header: "Betalingsform",
      accessor: "kjopsinfo.betalingsform",
      filterMethod: filterIncludeCaseInsensitive
    },
    {
      Header: "Pris",
      accessor: "kjopsinfo.price",
      filterMethod: filterIncludeCaseInsensitive
    }
  ];
  return (
    <div>
      {oblater && oblater.length > 0 && (
        <ReactTable
          className="-striped -highlight"
          data={oblater}
          columns={columns}
          filterable
          noDataText="Ingen oblater"
        />
      )}
    </div>
  );
};

Oblattabell.propTypes = {
  oblater: PropTypes.arrayOf(PropTypes.shape({}))
};

Oblattabell.defaultProps = {
  oblater: []
};

export default Oblattabell;
