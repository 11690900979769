import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { brukerShape, historyShape, locationShape } from "../shapes";
import { getServicebrukere as getServicebrukereAction, getBrukereSelector } from "../../state/brukere";
import BrukereTable from "./BrukereTable";
import sitePaths from "../navigation/sitePaths";
import AddBrukerContainer from "../brukere/AddBrukerContainer";
import BrukerePageHeader from "./BrukerePageHeader";

export const onBrukerSelected = (bruker, history) => {
  history.push({
    pathname: sitePaths.userSelectedWithKey(bruker.brukerId),
    state: { bruker }
  });
};

export class BrukereContainer extends Component {
  static propTypes = {
    brukere: PropTypes.arrayOf(PropTypes.shape(brukerShape)),
    getServicebrukere: PropTypes.func.isRequired,
    history: PropTypes.shape(historyShape).isRequired,
    location: PropTypes.shape(locationShape).isRequired
  };

  static defaultProps = {
    brukere: undefined
  };

  state = {
    loadingBrukere: true
  };

  componentDidMount() {
    const { getServicebrukere } = this.props;
    getServicebrukere()
      .then(() => {
        this.setState({ loadingBrukere: false });
      })
      .catch(() => this.setState({ loadingBrukere: false }));
  }

  render() {
    const { brukere, history, location } = this.props;
    const { loadingBrukere } = this.state;
    const selectedBrukerId = location.state && location.state.bruker ? location.state.bruker.brukerId : "";
    return [
      <section className="header-section">
        <BrukerePageHeader />,
      </section>,
      <section className="add-user-section">
        <AddBrukerContainer />
      </section>,
      <section>
        <BrukereTable
          brukere={brukere}
          loading={loadingBrukere}
          selectedBrukerId={selectedBrukerId}
          onBrukerSelected={bruker => onBrukerSelected(bruker, history)}
        />
      </section>
    ];
  }
}

const mapStateToProps = state => ({
  brukere: getBrukereSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getServicebrukere: () => dispatch(getServicebrukereAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(BrukereContainer);
