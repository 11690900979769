import jwtDecode from "jwt-decode";
import { User } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";
import { getEnvironmentVariable } from "utils/environment";

export interface OneloginAccessToken {
  jti: string;
  iat: number;
  sub: string;
  "bym-id": string;
  roles: string[];
  scope: string;
  email: string;
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
}

export const oidcBaseUrl = getEnvironmentVariable("REACT_APP_ONELOGIN_AUTHORITY");

export const oidcConfig: AuthProviderProps = {
  authority: getEnvironmentVariable("REACT_APP_ONELOGIN_AUTHORITY"),
  client_id: getEnvironmentVariable("REACT_APP_ONELOGIN_CLIENT_ID"),
  redirect_uri: getEnvironmentVariable("REACT_APP_ONELOGIN_REDIRECT_URI"),
  silent_redirect_uri: getEnvironmentVariable("REACT_APP_ONELOGIN_SILENT_REDIRECT_URI"),
  post_logout_redirect_uri: getEnvironmentVariable("REACT_APP_ONELOGIN_POST_LOGOUT_REDIRECT_URI"),
  automaticSilentRenew: true,
  response_type: "code",
  scope: "openid profile",
  resource: "https://piggav.no",
  onSigninCallback: user => {
    /**
     * You must provide an implementation of onSigninCallback to oidcConfig to remove the payload from the URL upon successful login.
     * Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.
     */
    window.history.replaceState({}, document.title, "/login");
  }
};

export function getUser() {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
  return !oidcStorage ? null : User.fromStorageString(oidcStorage);
}

export function getBymBrukerId(accessToken?: string) {
  let at = accessToken ?? getUser()?.access_token ?? undefined;
  if (!at) return undefined;
  return jwtDecode<OneloginAccessToken>(accessToken)["bym-id"];
}
