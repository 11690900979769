import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import Button from "reactstrap/lib/Button";
import Table from "reactstrap/lib/Table";

import Kjoretoy from "./Kjoretoy";

class Kjoreotytabell extends React.Component {
  propTypes = {
    kjoreotyliste: PropTypes.arrayOf(PropTypes.shape({})),
    opdaterKjoreotyListeType: PropTypes.func.isRequired,
    slettKjoretoyoyFraListe: PropTypes.func.isRequired
  };

  defaultProps = {
    kjoreotyliste: []
  };

  state = {
    typeOblat: ""
  };

  render() {
    const {
      kjoreotyliste,
      // opdaterKjoreotyListeType,
      slettKjoretoyoyFraListe
    } = this.props;
    const { typeOblat } = this.state;
    return (
      <React.Fragment>
        <Table dark>
          <thead>
            <tr>
              <th>Registreringsnummer</th>
              <th>Type</th>
              <th>Eier/Beskrivelse</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {map(kjoreotyliste, item => (
              <Kjoretoy
                key={item.id}
                kjoretoyId={item.id}
                registreringsnummer={item.registreringsnummer}
                kjoretoytype={item.kjoretoytype}
                beskrivelse={item.beskrivelse}
                // opdaterKjoreotyListeType={opdaterKjoreotyListeType}
                slettKjoretoyoyFraListe={slettKjoretoyoyFraListe}
              />
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default Kjoreotytabell;
