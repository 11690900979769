import KonfigurasjonForm from "./KonfigurasjonForm";
import NyKonfigurasjonForm from "./NyKonfigurasjonForm";
import "./Systemkonfigurasjon.css";
import { Systemkonfigurasjon as ISystekmkonfigurasjon } from "api/interfaces";

interface SystemkonfigurasjonProps {
  systemkonfigurasjon: Array<ISystekmkonfigurasjon>;
  postSystemkonfigurasjon: Function;
  updateSystemkonfigurasjon: Function;
}

export default function Systemkonfigurasjon({
  systemkonfigurasjon = [],
  postSystemkonfigurasjon,
  updateSystemkonfigurasjon
}: SystemkonfigurasjonProps) {
  return (
    <section className="konfigurasjon-page">
      <h1 className="konfigurasjon-title">Systemkonfigurasjon</h1>
      <div className="konfigurasjon-body">
        <h2>ADMINISTRASJON</h2>
        {systemkonfigurasjon
          .filter(item => item.kategori === "ADMINISTRASJON")
          .map(item => (
            <KonfigurasjonForm
              key={`${item.id}`}
              konfigurasjon={item}
              updateSystemkonfigurasjon={updateSystemkonfigurasjon}
            />
          ))}
        <h2 className="mt-3">SESONG</h2>
        {systemkonfigurasjon
          .filter(item => item.kategori === "SESONG")
          .map(item => (
            <KonfigurasjonForm
              key={item.id}
              konfigurasjon={item}
              updateSystemkonfigurasjon={updateSystemkonfigurasjon}
            />
          ))}
        <h2 className="mt-3">OBLAT</h2>
        {systemkonfigurasjon
          .filter(item => item.kategori === "OBLAT")
          .map(item => (
            <KonfigurasjonForm
              key={item.id}
              konfigurasjon={item}
              updateSystemkonfigurasjon={updateSystemkonfigurasjon}
            />
          ))}
      </div>
      <div className="konfigurasjon-add-form">
        <NyKonfigurasjonForm postSystemkonfigurasjon={postSystemkonfigurasjon} />
      </div>
    </section>
  );
}
