import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Kjoretoy from "./Kjoretoy";
import {
  getOblattyper as getOblattyperAction,
  getKjoretoyTyper as getKjoretoyTyperAction,
  getPerioder as getPerioderAction,
  getOblattyperSelector,
  getPerioderSelector,
  getKjoretoytyperSelector
} from "../../state/oblattyper";

import { mergeArrays } from "../../utils/Helperfunctions";

import {
  getOblaterForKjoretoy as getOblaterForKjoretoyAction,
  getKjoreotySelector,
  resetOblaterForKjoretoy as resetOblaterForKjoretoyAction,
  getKjoreotyFiltersSelector,
  setKjoretoyOblatFilter
} from "../../state/kjoretoyOblater";

export class KjoreotyConatainer extends Component {
  static propTypes = {
    oblattyper: PropTypes.arrayOf(PropTypes.shape()),
    perioder: PropTypes.arrayOf(PropTypes.shape()),
    kjoretoytyper: PropTypes.arrayOf(PropTypes.shape()),
    kjoreoty: PropTypes.arrayOf(PropTypes.shape()),
    getOblattyper: PropTypes.func.isRequired,
    resetOblaterForKjoretoy: PropTypes.func.isRequired,
    postOblattype: PropTypes.func.isRequired,
    updateOblattype: PropTypes.func.isRequired,
    getKjoretoytyper: PropTypes.func.isRequired,
    getPerioder: PropTypes.func.isRequired,
    getOblaterForKjoretoy: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.shape({}).isRequired
  };

  static defaultProps = {
    oblattyper: [],
    perioder: [],
    kjoretoytyper: [],
    kjoreoty: {}
  };

  constructor(props) {
    super(props);
    const { filters } = this.props;
    const { kjoretoyInput, oblatTableFilters } = filters;
    this.oblatFilters = oblatTableFilters;
    this.state = { regnummer: kjoretoyInput };
    this.filterChangeTimer = null;
  }

  componentDidMount() {
    const { getOblattyper, getKjoretoytyper, getPerioder, resetOblaterForKjoretoy } = this.props;
    //resetOblaterForKjoretoy();
    getOblattyper();
    getKjoretoytyper();
    getPerioder();
  }

  componentWillUnmount = () => {
    const { setFilters } = this.props;
    const { regnummer } = this.state;
    setFilters({
      kjoretoyInput: regnummer,
      oblatTableFilters: [...this.oblatFilters]
    });
    this.oblatFilters = [];
    clearTimeout(this.filterChangeTimer);
    this.filterChangeTimer = null;
  };

  onTableFiltersChange = (filters, col, filterValue) => {
    clearTimeout(this.filterChangeTimer);
    this.filterChangeTimer = setTimeout(() => {
      this.oblatFilters = mergeArrays(this.oblatFilters, [{ id: col.id, value: filterValue }], "id");
    }, 500);
  };

  getOblaterForKjoretoy = (kunInnevaerendeSesong = false) => {
    const { getOblaterForKjoretoy } = this.props;
    const { regnummer } = this.state;
    getOblaterForKjoretoy(regnummer.toUpperCase(), kunInnevaerendeSesong).then(() => {
      this.render();
    });
  };

  handleChange = (f, value) => {
    this.setState({
      [f]: value
        .toUpperCase()
        .replace(/[^A-Z^ ^Æ^ ^Ø^ Å^ ^0-9^]/g, "")
        .replace(/\s/g, "")
        .trim()
    });
  };

  render() {
    const { oblattyper, postOblattype, updateOblattype, perioder, kjoretoytyper, kjoreoty, filters } = this.props;
    const { regnummer } = this.state;
    const { oblatTableFilters } = filters;

    return (
      <Kjoretoy
        oblattyper={oblattyper}
        postOblattype={postOblattype}
        updateOblattype={updateOblattype}
        perioder={perioder}
        kjoretoytyper={kjoretoytyper}
        kjoreoty={kjoreoty}
        regnummer={regnummer}
        handleChange={this.handleChange}
        getOblaterForKjoretoy={this.getOblaterForKjoretoy}
        filters={oblatTableFilters}
        onFilterChange={this.onTableFiltersChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  oblattyper: getOblattyperSelector(state),
  perioder: getPerioderSelector(state),
  kjoretoytyper: getKjoretoytyperSelector(state),
  kjoreoty: getKjoreotySelector(state),
  filters: getKjoreotyFiltersSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getOblattyper: () => dispatch(getOblattyperAction()),
  getKjoretoytyper: () => dispatch(getKjoretoyTyperAction()),
  getPerioder: () => dispatch(getPerioderAction()),
  resetOblaterForKjoretoy: () => dispatch(resetOblaterForKjoretoyAction()),
  getOblaterForKjoretoy: (regnummer, kunInnevaerendeSesong) =>
    dispatch(getOblaterForKjoretoyAction(regnummer, kunInnevaerendeSesong)),
  setFilters: filters => dispatch(setKjoretoyOblatFilter(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(KjoreotyConatainer);
