import Api from "../api";

// types
const GET_BRUKER = "GET_BRUKER";
const GET_BRUKER_ERROR = "GET_BRUKER_ERROR";

export default function reducer(state = { bruker: undefined }, action = {}) {
  if (action.type === GET_BRUKER) {
    return {
      ...state,
      bruker: action.payload
    };
  } else if (action.type === GET_BRUKER_ERROR) {
    return {
      ...state,
      bruker: {},
      error: action.payload
    };
  }
  return state;
}

function getBrukerSuccess(payload) {
  return { type: GET_BRUKER, payload };
}
function getBrukerError(payload) {
  return { type: GET_BRUKER_ERROR, payload };
}

export function getBruker(brukerId) {
  return dispatch =>
    Api.getBruker(brukerId)
      .then(response => {
        // dispatch(toastSuccess(`Hentet Bruker med brukerId ${brukerId}`));
        dispatch(getBrukerSuccess(response.data.result));
      })
      .catch(error => {
        // dispatch(toastError(error));
        dispatch(getBrukerError("Kunne ikke hante bruker"));
      });
}

export const getBrukerSelector = state => state.bruker.bruker;
