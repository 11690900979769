import PropTypes from "prop-types";
import { useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const KundeInfo = ({ kunde, updateKunde, onDeleteKunde }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <div className="kunde-view">
      <span key="">
        <b>BrukerId:</b>
        {kunde.brukerId}
      </span>
      <span key="kundetypeId">
        <b>Kundetype:</b>
        {kunde.kundetypeId === 1 ? "Privat" : "Bedrift"}
      </span>
      <br />

      {kunde.kundetypeId === 2 && (
        <div>
          <span key="organisasjonsnummer">
            <b>organisasjonsnummer: </b>
            {kunde.organisasjonsnummer}
          </span>
          <br />
          <span key="bedriftsnavn">
            <b>bedriftsnavn: </b>
            {kunde.bedriftsnavn}
          </span>
          <br />
          <span key="adresse">
            <b>adresse: </b>
            {kunde.adresse}
          </span>
          <br />
          <span key="adresse2">
            <b>adresse2: </b>
            {kunde.adresse2}
          </span>
          <br />
          <span key="postnummer">
            <b>postnummer: </b>
            {kunde.postnummer}
          </span>
          <br />
          <span key="poststed">
            <b>poststed: </b>
            {kunde.poststed}
          </span>
          <br />
          <span key="land">
            <b>land: </b>
            {kunde.land}
          </span>
          <br />
          <span key="kontaktperson">
            <b>kontaktperson: </b>
            {kunde.kontaktperson}
          </span>
          <br />
          <span key="telefonnummer">
            <b>telefonnummer: </b>
            {kunde.telefonnummer}
          </span>
          <br />
        </div>
      )}
      <span key="samtykkerTilVilkaar">
        <b>samtykkerTilVilkaar: </b>
        <input type="checkbox" checked={kunde.samtykkerTilVilkaar} disabled />
      </span>
      <span key="samtykkerTilEpostvarsler">
        <b>samtykkerTilEpostvarsler: </b>
        <input type="checkbox" checked={kunde.samtykkerTilEpostvarsler} disabled />
      </span>
      <span key="fritattMinstebelopFaktura">
        <b>Fritatt minstebelop faktura: </b>
        <input
          type="checkbox"
          checked={kunde.fritattMinstebelopFaktura}
          disabled
          // disabled={!erBrukerAdmin()}
          // onChange={handleChangefritattMinstebelopFaktura}
        />
      </span>
      <br />
      <span>
        <b>opprettet:</b>
        {kunde.opprettet}
      </span>
      <span>
        <b>sistEndret:</b>
        {kunde.sistEndret}
      </span>
      <Button style={ { marginTop: "10px" }} color="danger" onClick={() => setShowDeleteDialog(true)}>Slett kunde</Button>
      {showDeleteDialog &&
      <Modal isOpen={showDeleteDialog} toggle={() => setShowDeleteDialog(false)}>
        <ModalHeader>Slette kunde<div>{kunde.brukerId}</div></ModalHeader>
        <ModalBody>
          <Alert color="danger" className="text-center">
          Har du verifisert at det er den faktiske eieren av kontoen som ber om at den skal slettes?
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowDeleteDialog(false)}>Avbryt</Button>
          <Button
            color="danger"
            onClick={() => onDeleteKunde(kunde.brukerId)}
          >
            Ja, slett
          </Button>
        </ModalFooter>
      </Modal>
      }
    </div>
  );
};

KundeInfo.propTypes = {
  kunde: PropTypes.shape({}).isRequired,
  updateKunde: PropTypes.func.isRequired,
  onDeleteKunde: PropTypes.func.isRequired
};

export default KundeInfo;
