import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import map from "lodash/map";
import Select from "react-select";
import { OBLAT_STATUS } from "constants/Constants";
import OblatLog from "./OblatLog";
import {
  getKundedata as getKundedataAction,
  updateKunde as updateKundeAction,
  getOblaterForKunde as getOblaterForKundeAction,
  getKjoretoyForKunde as getKjoretoyForKundeAction,
  kansellerOblatForKunde as kansellerOblatForKundeAction,
  hentOblatLogg as hentOblatLoggKundeAction,
  hentKjoretoyForBruker as hentKjoretoyForBrukerAction,
  hentKjoretoyTyper as hentKjoretoyTyperAction,
  opprettKjoretoyForBruker as opprettKjoretoyForBrukerAction,
  endreOblatForBruker as endreOblatForBrukerAction,
  getKundeOblaterSelector,
  getKundeErrorSelector,
  getOblaterSelector,
  getKjoretoySelector,
  getKundeSelector,
  getKundetyper,
  getKundetyperSelector
} from "../../state/kunde";

import "./oblatActions.css";

class EndreOblatContainer extends Component {
  static propTypes = {
    showOnlyThisSeason: PropTypes.bool.isRequired,
    selectedBrukerId: PropTypes.string.isRequired,
    oblat: PropTypes.shape({}).isRequired,
    hentOblatLogg: PropTypes.func.isRequired,
    hentKjoretoyForBruker: PropTypes.func.isRequired,
    hentKjoretoyTyper: PropTypes.func.isRequired,
    getOblaterForKunde: PropTypes.func.isRequired,
    opprettKjoretoyForBruker: PropTypes.func.isRequired,
    endreOblatForBruker: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired
  };

  state = {
    editOpen: false,
    laster: false,
    logg: [],
    kjoretoyForBruker: [],
    showOprettKjoretoy: false,
    regnummer: "",
    kjoretoyType: "",
    kjoretoyOpprettet: false,
    postKjoretoyError: "",
    valgtKjoretoy: null,
    kommentar: "",
    oblatEndretSuccess: false,
    oblatEndretFail: ""
  };

  onKjoretoySelect = e => {
    this.setState({ valgtKjoretoy: e });
  };

  toggleEdit = () => {
    const {
      hentOblatLogg,
      hentKjoretoyForBruker,
      oblat,
      selectedBrukerId,
      hentKjoretoyTyper,
      getOblaterForKunde,
      showOnlyThisSeason
    } = this.props;
    const { editOpen } = this.state;
    this.setState({ editOpen: !editOpen, laster: true });

    if (!editOpen) {
      hentOblatLogg(selectedBrukerId, oblat.id).then(res => {
        this.setState({ laster: false, logg: res.data.result });
      });
      hentKjoretoyForBruker(selectedBrukerId).then(res => {
        this.setState({ laster: false, kjoretoyForBruker: res.data.result });
      });
      hentKjoretoyTyper().then(res => {
        this.setState({ laster: false, kjoretoytyper: res.data.result });
      });
    } else {
      getOblaterForKunde(selectedBrukerId, showOnlyThisSeason);
    }
  };

  handleRegisterKjoretoy = () => {
    const { hentKjoretoyForBruker, selectedBrukerId, opprettKjoretoyForBruker } = this.props;
    const { regnummer, kjoretoyType } = this.state;
    this.setState({
      kjoretoyOpprettet: "",
      postKjoretoyError: "",
      laster: true
    });
    opprettKjoretoyForBruker(selectedBrukerId, regnummer, kjoretoyType)
      .then(res => {
        this.setState({
          laster: false,
          kjoretoyForBruker: res.data.result,
          kjoretoyOpprettet: true,
          showOprettKjoretoy: false
        });
        hentKjoretoyForBruker(selectedBrukerId).then(kjoretoyResult => {
          this.setState({
            laster: false,
            kjoretoyForBruker: kjoretoyResult.data.result
          });
        });
      })
      .catch(err => {
        this.setState({
          laster: false,
          postKjoretoyError:
            err.response && err.response.data ? err.response.data.errorMessage : "Kunne ikke registrere kjøretøy"
        });
      });
  };

  handleEditOblat = () => {
    const { selectedBrukerId, oblat, endreOblatForBruker, hentOblatLogg } = this.props;
    const { kommentar, valgtKjoretoy } = this.state;
    this.setState({
      oblatEndretSuccess: false,
      oblatEndretFail: null,
      laster: true
    });
    if (valgtKjoretoy) {
      endreOblatForBruker(selectedBrukerId, oblat.id, valgtKjoretoy.value, kommentar)
        .then(() => {
          this.setState({
            kommentar: "",
            valgtKjoretoy: null,
            oblatEndretSuccess: true
          });
          hentOblatLogg(selectedBrukerId, oblat.id)
            .then(res => {
              this.setState({ laster: false, logg: res.data.result });
            })
            .catch(err => {
              this.setState({ laster: false, error: err });
            });
        })
        .catch(err => {
          this.setState({
            laster: false,
            oblatEndretFail:
              err.response && err.response.data ? err.response.data.errorMessage : "Kunne ikke endre oblat"
          });
        });
    } else {
      this.setState({
        laster: false,
        oblatEndretFail: "Velg kjøretøy du ønsker å endre oblat til"
      });
    }
  };

  render() {
    const { oblat, editable } = this.props;
    const {
      editOpen,
      laster,
      logg,
      kjoretoyForBruker,
      kjoretoytyper,
      showOprettKjoretoy,
      regnummer,
      kjoretoyType,
      kjoretoyOpprettet,
      postKjoretoyError,
      valgtKjoretoy,
      kommentar,
      oblatEndretSuccess,
      oblatEndretFail
    } = this.state;

    const kjoretoyOptions = kjoretoyForBruker
      ? map(kjoretoyForBruker, k => ({
          label: k ? `${k.registreringsnummer} - ${k.beskrivelse || ""}` : "",
          value: k ? k.id : ""
        }))
      : [];

    return (
      <>
        <Button
          className="oblat-action-button"
          color="warning"
          size="sm"
          disabled={!editable}
          onClick={this.toggleEdit}
        >
          {oblat.statusId === OBLAT_STATUS.OVERFORT ? "Overført" : "Overfør"}
        </Button>
        <Modal className="oblatdetaljer" isOpen={editOpen} toggle={this.toggleEdit}>
          <ModalHeader toggle={this.toggleEdit}>Endre oblat</ModalHeader>
          <ModalBody>
            <div className="oblatdetaljer-section endre-oblat-section">
              {/* Opprett kjøretøy for bruker -  */}

              <div className="endre-oblat-section-velg-Kjoretoy">
                {oblatEndretSuccess && <div className="alert alert-success">Oblat ble oppdatert</div>}
                {oblatEndretFail && oblatEndretFail !== "" && (
                  <div className="alert alert-danger">Kunne ikke endre oblat: {oblatEndretFail}</div>
                )}
                <div>
                  <strong>Nåværende kjøretøy</strong>
                  <input className="text-input" value={oblat.kjoretoy.registreringsnummer} disabled />
                </div>
                <div>
                  <strong>Endre Til kjøretøy</strong>
                  <Select
                    noOptionsMessage={() => ""}
                    options={kjoretoyOptions}
                    value={valgtKjoretoy}
                    onChange={this.onKjoretoySelect}
                    isSearchable
                  />
                </div>

                {kjoretoyOpprettet && <div className="alert alert-success">Kjøretøy ble opprettet</div>}
                {postKjoretoyError && (
                  <div className="alert alert-danger">Kunne ikke opprette kjøretøy: {postKjoretoyError}</div>
                )}
                <div className={showOprettKjoretoy ? "legg-til-kjoretoy-form" : ""}>
                  {showOprettKjoretoy && (
                    <div>
                      <div className="text-input-section">
                        <strong>Registreringsnummer</strong>
                        <input
                          className="text-input"
                          type="string"
                          value={regnummer}
                          onChange={e => this.setState({ regnummer: e.target.value })}
                        />
                      </div>
                      <div>
                        <strong>Type(Valgfri)</strong>
                        <select
                          className="text-input"
                          value={kjoretoyType}
                          onChange={e => this.setState({ kjoretoyType: e.target.value })}
                        >
                          <option value="">Velg type</option>
                          {map(kjoretoytyper, item => (
                            <option value={item.id}>{item.navn}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <Button
                    color="link"
                    onClick={() =>
                      this.setState({
                        showOprettKjoretoy: !showOprettKjoretoy,
                        kjoretoyOpprettet: false,
                        postKjoretoyError: "",
                        regnummer: "",
                        kjoretoyType: ""
                      })
                    }
                  >
                    {showOprettKjoretoy ? "Avbryt" : "Legg til kjøretøy"}
                  </Button>
                  {showOprettKjoretoy && (
                    <Button color="success" onClick={this.handleRegisterKjoretoy} disabled={laster}>
                      Opprett kjøretøy
                    </Button>
                  )}
                </div>
                <div>
                  <strong>Kommentar</strong>
                  <textarea
                    type="string"
                    rows={5}
                    value={kommentar}
                    onChange={e => this.setState({ kommentar: e.target.value })}
                  />
                </div>
                <Button color="success" onClick={this.handleEditOblat} disabled={laster || !editable}>
                  {laster ? "Lagrer..." : "Endre oblat til valgt kjøretøy"}
                </Button>
              </div>
            </div>

            <div className="oblatdetaljer-section">
              <OblatLog oblat={oblat} logg={logg} laster={laster} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleEdit}>
              Lukk
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  kunde: getKundeSelector(state),
  oblater: getOblaterSelector(state),
  kundeError: getKundeErrorSelector(state),
  kundetyper: getKundetyperSelector(state),
  kundeOblater: getKundeOblaterSelector(state),
  kjoretoy: getKjoretoySelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateKunde: (brukerId, payload) => dispatch(updateKundeAction(brukerId, payload)),
  getKundetyper: () => dispatch(getKundetyper()),
  getKundedata: brukerId => dispatch(getKundedataAction(brukerId)),
  getKjoretoyForKunde: brukerId => dispatch(getKjoretoyForKundeAction(brukerId)),
  getOblaterForKunde: (brukerId, kunInnevaerendeSesong) =>
    dispatch(getOblaterForKundeAction(brukerId, kunInnevaerendeSesong)),
  kansellerOblatForKunde: (brukerId, varekjopId, oblatId) =>
    dispatch(kansellerOblatForKundeAction(brukerId, varekjopId, oblatId)),
  hentKjoretoyForBruker: brukerId => hentKjoretoyForBrukerAction(brukerId),
  hentKjoretoyTyper: () => hentKjoretoyTyperAction(),
  hentOblatLogg: (brukerId, oblatId) => hentOblatLoggKundeAction(brukerId, oblatId),
  opprettKjoretoyForBruker: (brukerId, regnummer, type) => opprettKjoretoyForBrukerAction(brukerId, regnummer, type),
  endreOblatForBruker: (kundeId, oblatId, kjoretoyId, kommentar) =>
    endreOblatForBrukerAction(kundeId, oblatId, kjoretoyId, kommentar)
});

export default connect(mapStateToProps, mapDispatchToProps)(EndreOblatContainer);
