import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { historyShape, locationShape } from "../shapes";
import {
  getKundeFiltersSelector,
  getCurrentKundeSelector,
  resetOnlyKundeFilters,
  resetOnlyKunderFilters,
  setOnlyKundeFilterAction,
  setOnlyKunderFilterAction,
  setCurrentKundeIdAction,
  deleteKunde as deleteKundeAction
} from "../../state/kunde";
import { getKunderData, getKunderSokData, getKunderSelector, getIsKunderLoading } from "../../state/kunder";
import KunderTable from "./KunderTable";
import sitePaths from "../navigation/sitePaths";
import PrivateRoute from "../navigation/PrivateRoute";
import Kunde from "./Kunde";
import BrukerePageHeader from "./KunderPageHeader";
import "./Kunder.css";
import { mergeArrays } from "../../utils/Helperfunctions";

export const onBrukerSelected = (bruker, history) => {
  history.push({
    pathname: sitePaths.userSelectedWithKey(bruker.brukerId),
    state: { bruker }
  });
};

export class BrukereContainer extends Component {
  static propTypes = {
    kunder: PropTypes.arrayOf(PropTypes.shape({})),
    history: PropTypes.shape(historyShape).isRequired,
    location: PropTypes.shape(locationShape).isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    kunderFilters: PropTypes.shape({}).isRequired,
    kundeFilters: PropTypes.shape({}).isRequired,
    currentKundeId: PropTypes.string.isRequired,
    setJustKunderFilters: PropTypes.func.isRequired,
    setJustKundeFilters: PropTypes.func.isRequired,
    resetJustKundeFilters: PropTypes.func.isRequired,
    setCurrentKundeId: PropTypes.func.isRequired,
    getKunderSok: PropTypes.func,
    isKunderLoading: PropTypes.bool,
    deleteKunde: PropTypes.func.isRequired
  };

  static defaultProps = {
    kunder: undefined
  };

  constructor(props) {
    super(props);

    this.updateKunderUpdateTime = null;
    this.updateKundeUpdateTime = null;
  }

  componentDidMount() {
    const { history, location, currentKundeId, setCurrentKundeId, getKunderSok } = this.props;
    if (
      isEmpty(location.state && location.state.bruker ? location.state.bruker.brukerId : "") &&
      !isEmpty(currentKundeId)
    )
      history.push({
        pathname: sitePaths.userSelectedWithKey(currentKundeId),
        state: { bruker: { brukerId: currentKundeId } }
      });
    else setCurrentKundeId(location.state && location.state.bruker ? location.state.bruker.brukerId : "");
    getKunderSok();
  }

  componentWillUnmount = () => {
    clearTimeout(this.updateKunderUpdateTime);
    clearTimeout(this.updateKunderUpdateTime);
    this.updateKunderTimer = null;
    this.updateKundeTimer = null;
  };

  onKunderFilterChange = (filters, col, filterValue) => {
    const { kunderFilters, setJustKunderFilters } = this.props;
    clearTimeout(this.updateKunderTimer);
    this.updateKunderTimer = setTimeout(() => {
      setJustKunderFilters(mergeArrays(kunderFilters, [{ id: col.id, value: filterValue }], "id"));
    }, 500);
  };

  onKundeFilterChange = (filters, col, filterValue) => {
    const { kundeFilters, setJustKundeFilters } = this.props;
    clearTimeout(this.updateKundeTimer);
    this.updateKundeTimer = setTimeout(() => {
      setJustKundeFilters(mergeArrays(kundeFilters, [{ id: col.id, value: filterValue }], "id"));
    }, 500);
  };

  onBrukerSelected = (bruker, history) => {
    const { resetJustKundeFilters, setCurrentKundeId } = this.props;
    resetJustKundeFilters();
    setCurrentKundeId(bruker.brukerId);
    history.push({
      pathname: sitePaths.userSelectedWithKey(bruker.brukerId),
      state: { bruker }
    });
  };

  onKundeDeleted = kundeId => {
    const { deleteKunde, history, getKunderSok } = this.props;
    deleteKunde(kundeId).then(() => {
      getKunderSok();
      history.push({
        pathname: sitePaths.kunderPage
      });
    });
  };

  render() {
    const { kunder, history, location, isAuthenticated, kunderFilters, kundeFilters, isKunderLoading, getKunderSok } =
      this.props;

    const selectedBrukerId = location.state && location.state.bruker ? location.state.bruker.brukerId : "";
    return (
      <>
        <section className="header-section">
          <BrukerePageHeader />
        </section>
        <div className="brukere-container">
          <div className="brukere-section">
            <KunderTable
              kunder={kunder}
              loading={isKunderLoading}
              selectedBrukerId={selectedBrukerId}
              getKunderSok={getKunderSok}
              onBrukerSelected={bruker => this.onBrukerSelected(bruker, history)}
              filters={kunderFilters}
              onKunderFilterChange={this.onKunderFilterChange}
            />
          </div>
          <div className="brukerinfo-section">
            <PrivateRoute
              path={sitePaths.kundeSelected}
              isAuthenticated={isAuthenticated}
              component={Kunde}
              componentProps={{
                filters: kundeFilters,
                onKundeFilterChange: this.onKundeFilterChange,
                onKundeDeleted: this.onKundeDeleted
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  kunder: getKunderSelector(state),
  kundeFilters: getKundeFiltersSelector(state).kunde,
  kunderFilters: getKundeFiltersSelector(state).kunder,
  currentKundeId: getCurrentKundeSelector(state),
  isKunderLoading: getIsKunderLoading(state)
});

const mapDispatchToProps = dispatch => ({
  setJustKunderFilters: filters => dispatch(setOnlyKunderFilterAction(filters)),
  setJustKundeFilters: filters => dispatch(setOnlyKundeFilterAction(filters)),
  resetJustKundeFilters: () => dispatch(resetOnlyKundeFilters()),
  resetJustKunderFilters: () => dispatch(resetOnlyKunderFilters()),
  setCurrentKundeId: kundeId => dispatch(setCurrentKundeIdAction(kundeId)),
  getAllKunderData: () => dispatch(getKunderData()),
  getKunderSok: (epost, brukerId) => {
    dispatch(getKunderSokData(epost, brukerId));
  },
  deleteKunde: kundeId => dispatch(deleteKundeAction(kundeId))
});

export default connect(mapStateToProps, mapDispatchToProps)(BrukereContainer);
