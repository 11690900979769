/* eslint-disable @typescript-eslint/no-implied-eval */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { CSSTransition } from "react-transition-group";
import { errorShape } from "../shapes";
import { errorSelector, resetToast, successSelector, warningSelector } from "../../state/toast/index";
import "./toast.css";

export function Toast({ error, success, warning, toastDismiss }) {
  if (success) {
    setTimeout(toastDismiss, 4500);
    return (
      <Alert className="toast-top-right" color="success" isOpen={!!success} toggle={toastDismiss}>
        {success || ""}
      </Alert>
    );
  }
  if (warning) {
    setTimeout(toastDismiss, 4500);
    return (
      <Alert className="toast-top-right" color="warning" isOpen={!!warning} toggle={toastDismiss}>
        {warning || ""}
      </Alert>
    );
  }
  let messageToUser = "";
  if (error) {
    setTimeout(toastDismiss, 10000);
    messageToUser = error.message;
    if (error.message === "Network Error") {
      messageToUser = "Kan ikke nå PiggdekkoblatService";
    }
    if (error.response) {
      const { status, data, statusText } = error.response;
      switch (status) {
        case 401:
          if (statusText === "Unauthorized") {
            messageToUser = "Du er ikke autorisert, logg inn på nytt.";
          }
          break;
        case 404:
          if (data === "token er ugyldig") {
            messageToUser = "Ugyldig token, logg inn på nytt.";
          }
          break;
        case 400:
          messageToUser = `Kall på ${error.config.url} gikk galt: ${error.response.data.errorMessage}`;
          break;
        case 403:
          messageToUser = `Kall på ${error.config.url} gikk galt: Din bruker har ikke tilgang`;
          break;
        case 500:
          messageToUser = `Kall på ${error.config.url} gikk galt på serveren: ${error.response.data.errorMessage}`;
          break;
        default:
          messageToUser = `Kall på ${error.config.url} gikk galt: ${status}: ${statusText}`;
      }
    }
  }
  return (
    <Alert className="toast-top-right" color="danger" isOpen={!!error} toggle={toastDismiss}>
      {messageToUser}
    </Alert>
  );
}

Toast.propTypes = {
  error: PropTypes.shape(errorShape),
  success: PropTypes.string,
  warning: PropTypes.string,
  toastDismiss: PropTypes.func.isRequired
};

Toast.defaultProps = {
  error: null,
  success: null,
  warning: null
};

const mapStateToProps = state => ({
  error: errorSelector(state),
  warning: warningSelector(state),
  success: successSelector(state)
});

const mapDispatchToProps = dispatch => ({
  toastDismiss: () => dispatch(resetToast())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(props => (
  <CSSTransition
    in={true}
    classNames="fade"
    timeout={{
      appear: 500,
      enter: 500,
      exit: 500
    }}
  >
    {props.success || props.warning || props.error ? <Toast {...props} /> : <></>}
  </CSSTransition>
));
