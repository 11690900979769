import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { every } from "lodash";
import sitePaths from "./sitePaths";

interface PrivateRouteProps extends RouteProps {
  tilgangskontroll: Array<() => boolean>;
  isAuthenticated: boolean;
  component: any;
  componentProps?: any;
}

export default function PrivateRoute({
  tilgangskontroll,
  isAuthenticated,
  component: Component,
  componentProps,
  ...rest
}: PrivateRouteProps) {
  return (
    <Route
      {...rest}
      render={(props: any) =>
        isAuthenticated && every(tilgangskontroll, kontroll => kontroll()) ? (
          <Component {...props} isAuthenticated {...componentProps} />
        ) : (
          <Redirect
            to={{
              pathname: sitePaths.loginPage,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
