import { Provider } from "react-redux";
import RouterContainer from "./features/navigation/Router";
import "./App.css";
import store from "./state/store";
import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from "auth/oidc";

function App() {
  return (
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <RouterContainer />
      </Provider>
    </AuthProvider>
  );
}

export default App;
