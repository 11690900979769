import { combineReducers } from "redux";
import kunde from "./kunde";
import kunder from "./kunder";
import bruker from "./bruker";
import toast from "./toast";
import brukere from "./brukere";
import serviceRoller from "./serviceRoller";
import systemkonfigurasjon from "./systemkonfigurasjon";
import oblattyper from "./oblattyper";
import kjoretoyOblater from "./kjoretoyOblater";
import oblatKjop from "./oblatKjop";

const rootReducer = combineReducers({
  toast,
  brukere,
  kunde,
  kunder,
  bruker,
  serviceRoller,
  systemkonfigurasjon,
  oblattyper,
  kjoretoyOblater,
  oblatKjop
});

export default rootReducer;
