import httpClient from "./httpClient";
import { PIGGDEKKOBLAT_PORTAL_CLIENT_ID } from "../constants/Constants";

// const localhostUrl = "http://localhost:5015";

export default class Api {
  static activateUserWithPassword(key, password) {
    // use axios directly for this call to skip httpClient's automatic error handling
    return httpClient.activateUserWithPassword(key, password);
  }

  static getKundedata(brukerId) {
    return httpClient.getKundedata(brukerId);
  }

  static getAllKunderData() {
    return httpClient.getAllKunder();
  }

  static getKunderSokData(epost, brukerId) {
    return httpClient.getKunderSok(epost, brukerId);
  }

  static getBrukerByEpost(epost) {
    return httpClient.getBrukerByEpost(epost);
  }

  static getKundetyper() {
    return httpClient.getKundetyper();
  }

  static getServicebrukere() {
    return httpClient.getServicebrukere();
  }

  static getBruker(brukerId) {
    return httpClient.getBruker(brukerId);
  }

  static updateKunde(brukerId, payload) {
    return httpClient.updateKunde(brukerId, payload);
  }

  static deleteKunde(brukerId) {
    return httpClient.deleteKunde(brukerId);
  }

  static getOblaterForKunde(brukerId, kunInnevaerendeSesong) {
    return httpClient.getOblaterForKunde(brukerId, kunInnevaerendeSesong);
  }

  static getServiceRoller() {
    return httpClient.getserviceRoller();
  }

  static addBruker(rollenavn, name, email) {
    return httpClient.postBruker({
      rollenavn,
      clientId: PIGGDEKKOBLAT_PORTAL_CLIENT_ID,
      navn: name,
      epost: email
    });
  }

  static getSystemkonfigurasjon() {
    return httpClient.getSystemkonfigurasjon();
  }

  static postSystemkonfigurasjon(navn, kategori, verdi) {
    return httpClient.postSystemkonfigurasjon(navn, kategori, verdi);
  }

  static deleteSystemkonfigurasjon(id) {
    return httpClient.deleteSystemkonfigurasjon(id);
  }

  static putSystemkonfigurasjon(id, payload) {
    return httpClient.putSystemkonfigurasjon(id, payload);
  }

  static getSystemkonfigurasjonById() {
    return httpClient.getSystemkonfigurasjonById();
  }

  static getOblattyper() {
    return httpClient.getOblattyper();
  }

  static postOblattype(payload) {
    return httpClient.postOblattype(payload);
  }

  static putOblattyper(id, payload) {
    return httpClient.putOblattyper(id, payload);
  }

  static getKjoretoyTyper() {
    return httpClient.getKjoretoyTyper();
  }

  static getKjoretoyForKunde(brukerId) {
    return httpClient.getKjoretoyForKunde(brukerId);
  }

  static kansellerOblatForKunde(brukerId, varekjopId, oblatId, kommentar, resterendeRefusjon, refusjonsdato, belop) {
    return httpClient.kansellerOblatForKunde(
      brukerId,
      varekjopId,
      oblatId,
      kommentar,
      resterendeRefusjon,
      refusjonsdato,
      belop
    );
  }

  static krediterOblatForKunde(brukerId, varekjopId, oblatId, kommentar, resterendeRefusjon, refusjonsdato, belop) {
    return httpClient.krediterOblatForKunde(
      brukerId,
      varekjopId,
      oblatId,
      kommentar,
      resterendeRefusjon,
      refusjonsdato,
      belop
    );
  }

  static getPerioder() {
    return httpClient.getPerioder();
  }

  static getKjoreotyMedOblater(regnummer, kunInnevaerendeSesong) {
    return httpClient.getKjoreotyMedOblater(regnummer, kunInnevaerendeSesong);
  }

  static hentOblatLogg(brukerId, oblatId) {
    return httpClient.hentOblatLogg(brukerId, oblatId);
  }

  static hentKjoretoyForBruker(brukerId) {
    return httpClient.hentKjoretoyForBruker(brukerId);
  }

  static hentKjoretoyTyper() {
    return httpClient.hentKjoretoyTyper();
  }

  static opprettKjoretoyForBruker(kundeId, varekjopId, oblatId) {
    return httpClient.opprettKjoretoyForBruker(kundeId, varekjopId, oblatId);
  }

  static postKjoretoyliste(brukerId, fil) {
    return httpClient.postKjoretoyliste(brukerId, fil);
  }

  static confirmKjoretoyliste(brukerId, liste, oblattype) {
    return httpClient.confirmKjoretoyliste(brukerId, liste, oblattype);
  }

  static endreOblatForBruker(kundeId, oblatId, kjoretoyId, kommentar) {
    return httpClient.endreOblatForBruker(kundeId, oblatId, kjoretoyId, kommentar);
  }

  static kalkulerRefusjonsbelopForOblat(brukerId, varekjopId, oblatId, refusjonsdato) {
    return httpClient.kalkulerRefusjonsbelopForOblat(brukerId, varekjopId, oblatId, refusjonsdato);
  }
}
