import React from "react";
import "./PublicHeader.css";
import logo from "../../byvaapenet.png";

function PublicHeader() {
  return (
    <header className="public-header">
      <img src={logo} className="App-logo-public" alt="logo" />
      <h1>OSLO KOMMUNE</h1>
    </header>
  );
}

export default PublicHeader;
