/**
 * Function that returns the value of a given environment variable.
 * It first checks if the variable is defined on the global window object,
 * if not it falls back to process.env.
 * The reason for this is that environment varibles are resolved at build time,
 * so to avoid running a build every time the application starts up in production
 * all REACT_APP_* environment variables are generated on the window object at startup
 * with the 'runtimeEnv.sh' script
 *
 */
export function getEnvironmentVariable(key: string, defaultValue?: string) {
  const env = window[key] || process.env[key];
  if (env != null) return env;
  if (defaultValue) return defaultValue;
  throw new Error(`Missing environment configuration with key '${key}'`);
}

export function isProduction() {
  const env = getEnvironmentVariable("REACT_APP_REACT_ENV", "development");
  return env === "production";
}
