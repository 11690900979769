import Api from "../api";
import { piggavKunde } from "constants/Authorization.ts";

// types
const GET_SERVICEROLLER = "GET_SERVICEROLLER";

export default function reducer(state = { serviceRoller: undefined }, action = {}) {
  if (action.type === GET_SERVICEROLLER) {
    return {
      ...state,
      serviceRoller: action.payload
    };
  }
  return state;
}

function getServiceRollerSuccess(payload) {
  return { type: GET_SERVICEROLLER, payload };
}

export function getServiceRoller() {
  return dispatch =>
    Api.getServiceRoller().then(response => {
      dispatch(getServiceRollerSuccess(response.data.result));
    });
}

export const getServiceRollerSelector = state => state.serviceRoller.serviceRoller?.filter(e => e.navn !== piggavKunde);
