import Api from "../api";
import { toastSuccess, toastError } from "./toast";

// types
const GET_OBLATER = "GET_OBLATER";
const GET_OBLATER_ERROR = "GET_OBLATER_ERROR";
const GET_KUNDE_DATA = "GET_KUNDE_DATA";
const GET_KUNDE_ERROR = "GET_KUNDE_ERROR";
const GET_KUNDETYPER = "GET_KUNDETYPER";
const GET_KJORETOY = "GET_KJORETOY";
const GET_KJORETOY_ERROR = "GET_KJORETOY_ERROR";
const SET_KUNDE_OG_KUNDE_FILTERS = "SET_KUNDE_FILTERS";
const SET_KUNDE_FILTER = "SET_KUNDE_FILTER";
const SET_KUNDER_FILTER = "SET_KUNDER_FILTER";
const SET_CURRENT_KUNDE_ID = "SET_CURRENT_KUNDE_ID";
const SET_CURRENT_KUNDE = "SET_CURRENT_KUNDE";

const kundeFilterInitialValues = () => [
  { id: "oblattype", value: "" },
  { id: "status", value: "" },
  { id: "kjoretoytype", value: "" },
  { id: "gyldigFra", value: "" },
  { id: "gyldigTil", value: "" },
  { id: "detaljer", value: "" }
];

const kunderFilterInitialValues = () => [
  { id: "epost", value: "" },
  { id: "kundeId", value: "" },
  { id: "navn", value: "" }
];

export default function reducer(
  state = {
    kunde: undefined,
    oblater: [],
    kjoretoy: [],
    kundetyper: [],
    filters: {
      kunder: kunderFilterInitialValues(),
      kunde: kundeFilterInitialValues()
    },
    selectedUser: ""
  },
  action = {}
) {
  if (action.type === GET_KUNDE_DATA) {
    return {
      ...state,
      kunde: action.payload,
      kundeError: ""
    };
  }
  if (action.type === GET_KUNDE_ERROR) {
    return {
      ...state,
      kunde: {},
      kundeError: action.payload
    };
  }
  if (action.type === GET_KUNDETYPER) {
    return {
      ...state,
      kundetyper: action.payload
    };
  }
  if (action.type === GET_OBLATER) {
    if (state.selectedUser && action.payload.brukerId !== state.selectedUser) {
      return state;
    }

    return {
      ...state,
      oblater: action.payload.oblater
    };
  }
  if (action.type === GET_OBLATER_ERROR) {
    return {
      ...state,
      oblater: []
    };
  }
  if (action.type === GET_KJORETOY) {
    return {
      ...state,
      kjoretoy: action.payload
    };
  }
  if (action.type === GET_KJORETOY_ERROR) {
    return {
      ...state,
      kjoretoy: []
    };
  }
  if (action.type === SET_KUNDE_OG_KUNDE_FILTERS) {
    return {
      ...state,
      filters: action.payload
    };
  }
  if (action.type === SET_KUNDE_FILTER) {
    return {
      ...state,
      filters: {
        ...state.filters,
        kunde: [...action.payload]
      }
    };
  }
  if (action.type === SET_KUNDER_FILTER) {
    return {
      ...state,
      filters: {
        ...state.filters,
        kunder: action.payload
      }
    };
  }
  if (action.type === SET_CURRENT_KUNDE_ID) {
    return {
      ...state,
      selectedUser: action.payload
    };
  }
  return state;
}

function getKundeSuccess(payload) {
  return { type: GET_KUNDE_DATA, payload };
}
function getKundeError(payload) {
  return { type: GET_KUNDE_ERROR, payload };
}

export function getKundedata(brukerId) {
  return dispatch =>
    Api.getKundedata(brukerId)
      .then(response => {
        dispatch(getKundeSuccess(response.data.result));
      })
      .catch(() => {
        dispatch(getKundeError("Kunne ikke hente kundedata"));
      });
}

export function updateKunde(brukerId, payload) {
  return dispatch =>
    Api.updateKunde(brukerId, payload)
      .then(() => {
        dispatch(toastSuccess(`Oppdaterte kunde med brukerId ${brukerId}`));
        dispatch(getKundedata(brukerId));
      })
      .catch(error => {
        dispatch(toastError(error));
      });
}

function getKundeTyperSuccess(payload) {
  return { type: GET_KUNDETYPER, payload };
}

export function getKundetyper() {
  return dispatch => Api.getKundetyper().then(response => dispatch(getKundeTyperSuccess(response.data.result)));
}

const getOblaterForKundeSuccess = (brukerId, payload) => ({
  type: GET_OBLATER,
  payload: { brukerId, oblater: payload }
});
const getOblaterForKundeError = () => ({
  type: GET_OBLATER_ERROR,
  payload: []
});

export function getOblaterForKunde(brukerId, kunInnevaerendeSesong) {
  return dispatch =>
    Api.getOblaterForKunde(brukerId, kunInnevaerendeSesong)
      .then(response => {
        dispatch(getOblaterForKundeSuccess(brukerId, response.data.result));
      })
      .catch(() => {
        dispatch(getOblaterForKundeError());
      });
}

const getKjoretoyForKundeSuccess = payload => ({
  type: GET_KJORETOY,
  payload
});
const getKjoretoyForKundeError = () => ({
  type: GET_KJORETOY_ERROR,
  payload: []
});

export function getKjoretoyForKunde(brukerId) {
  return dispatch =>
    Api.getKjoretoyForKunde(brukerId)
      .then(response => {
        dispatch(getKjoretoyForKundeSuccess(response.data.result));
      })
      .catch(() => {
        dispatch(getKjoretoyForKundeError());
      });
}

export function kansellerOblatForKunde(
  brukerId,
  varekjopId,
  oblatId,
  kommentar,
  resterendeRefusjon,
  refusjonsdato,
  belop
) {
  return Api.kansellerOblatForKunde(brukerId, varekjopId, oblatId, kommentar, resterendeRefusjon, refusjonsdato, belop);
}

export function krediterOblatForKunde(
  brukerId,
  varekjopId,
  oblatId,
  kommentar,
  resterendeRefusjon,
  refusjonsdato,
  belop
) {
  return Api.krediterOblatForKunde(brukerId, varekjopId, oblatId, kommentar, resterendeRefusjon, refusjonsdato, belop);
}

export function kalkulerRefusjonsbelopForOblat(brukerId, varekjopId, oblatId, refusjonsdato) {
  return Api.kalkulerRefusjonsbelopForOblat(brukerId, varekjopId, oblatId, refusjonsdato);
}

export function hentOblatLogg(brukerId, oblatId) {
  return Api.hentOblatLogg(brukerId, oblatId);
}
export function hentKjoretoyForBruker(brukerId) {
  return Api.hentKjoretoyForBruker(brukerId);
}
export function hentKjoretoyTyper() {
  return Api.hentKjoretoyTyper();
}

export function opprettKjoretoyForBruker(brukerId, regnummer, type) {
  return Api.opprettKjoretoyForBruker(brukerId, regnummer, type);
}

export function endreOblatForBruker(kundeId, oblatId, kjoretoyId, kommentar) {
  return Api.endreOblatForBruker(kundeId, oblatId, kjoretoyId, kommentar);
}

export const setKundeOgKunderFiltersAction = filters => ({
  type: SET_KUNDE_OG_KUNDE_FILTERS,
  payload: filters
});

export const setOnlyKundeFilterAction = filters => ({
  type: SET_KUNDE_FILTER,
  payload: filters
});

export const setOnlyKunderFilterAction = filters => ({
  type: SET_KUNDER_FILTER,
  payload: filters
});

export const setCurrentKundeIdAction = kundeId => ({
  type: SET_CURRENT_KUNDE_ID,
  payload: kundeId
});

export const resetOnlyKundeFilters = () => setOnlyKundeFilterAction(kundeFilterInitialValues());

export const resetOnlyKunderFilters = () => setOnlyKunderFilterAction(kunderFilterInitialValues());

export const resetKundeOgKunderFilters = () =>
  setKundeOgKunderFiltersAction({
    kunde: kundeFilterInitialValues(),
    kunder: kunderFilterInitialValues()
  });

export function deleteKunde(brukerId) {
  return dispatch =>
    Api.deleteKunde(brukerId)
      .then(() => {
        dispatch(toastSuccess(`Slettet kunde med brukerId ${brukerId}`));
        dispatch(setCurrentKundeIdAction(undefined));
      })
      .catch(error => {
        dispatch(toastError(error));
      });
}

export const getOblatLoggSelector = state => state.kunde.oblater.valgtOblatLgg;

export const getKundeOblaterSelector = state => state.kunde.oblater;
export const getKundeSelector = state => state.kunde.kunde;
export const getOblaterSelector = state => state.kunde.oblater;
export const getKjoretoySelector = state => state.kunde.kjoretoy;
export const getKundeErrorSelector = state => state.kunde.kundeError;
export const getKundetyperSelector = state => state.kunde.kundetyper;
export const getKundeFiltersSelector = state => state.kunde.filters;
export const getCurrentKundeSelector = state => state.kunde.selectedUser;
