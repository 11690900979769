/* eslint-disable react/jsx-filename-extension */
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "open-iconic/font/css/open-iconic-bootstrap.min.css";
import "./index.css";
import "./react-table.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import nb from "date-fns/locale/nb";
import App from "./App";
registerLocale("nb", nb); // register it with the name you want
setDefaultLocale("nb");

ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
