import React from "react";

import MengdekjopForm from "./MengdekjopForm";
import EnkeltkjopForm from "./EnkeltkjopForm";
import Oblattabell from "./Oblattabell";
import Kjoreotytabell from "./Kjoreotytabell";
import "./Oblatregistrering.css";

interface OblatRegistreringProps {
  bymId: string;
  postKjoretoyliste: Function;
  kjoreotyliste: Array<any>;
  oblater: Array<any>;
  opdaterKjoreotyListeType: Function;
  slettKjoretoyoyFraListe: Function;
  resetKjoretoyliste: Function;
  confirmKjoretoyliste: Function;
  getOblaterForAdmin: Function;
}

class Oblatregistrering extends React.Component<OblatRegistreringProps> {
  defaultProps = {
    kjoreotyliste: []
  };

  state = {
    oblattype: "",
    singleKjoretoy: "",
    singleBeskrivelse: "",
    singleKjoreotyType: "",
    singleOblattype: "",
    kunInnevaerendeSesong: true
  };

  componentDidMount = () => {
    const { getOblaterForAdmin, bymId } = this.props;
    const { kunInnevaerendeSesong } = this.state;

    getOblaterForAdmin(bymId, kunInnevaerendeSesong);
  };

  onChange = (field, value) => this.setState({ [field]: value });

  onRadioBtnClick = type => {
    this.setState({ oblattype: type });
  };

  confirmsingleKjoretoy = () => {
    const { confirmKjoretoyliste, bymId } = this.props;
    const { singleKjoretoy, singleBeskrivelse, singleKjoreotyType, singleOblattype } = this.state;
    const kjoreotyliste = [
      {
        registreringsnummer: singleKjoretoy,
        kjoretoytype: singleKjoreotyType,
        beskrivelse: singleBeskrivelse
      }
    ];
    confirmKjoretoyliste(bymId, kjoreotyliste, singleOblattype).then(() => {
      this.setState({
        singleKjoretoy: "",
        singleBeskrivelse: "",
        singleKjoreotyType: "",
        singleOblattype: ""
      });
    });
  };

  confirmKjoretoyliste = () => {
    const { confirmKjoretoyliste, bymId, kjoreotyliste } = this.props;
    const { oblattype } = this.state;
    confirmKjoretoyliste(bymId, kjoreotyliste, oblattype);
  };

  resetKjoretoyliste = () => {
    const { resetKjoretoyliste } = this.props;
    resetKjoretoyliste();
    this.setState({ oblattype: "" });
  };

  uploadFile = e => {
    const { bymId, postKjoretoyliste } = this.props;
    e.preventDefault();
    const formData = new FormData();
    const file = e.target.bulkKjopImportExcelInput.files[0];
    formData.append("file", file);
    postKjoretoyliste(bymId, file);
  };

  onKunInnevaerendeSesongChangeClick = () => {
    const { getOblaterForAdmin, bymId } = this.props;
    const { kunInnevaerendeSesong } = this.state;

    const newState = !kunInnevaerendeSesong;
    this.setState({
      kunInnevaerendeSesong: newState
    });

    getOblaterForAdmin(bymId, newState);
  };

  render() {
    const { opdaterKjoreotyListeType, slettKjoretoyoyFraListe, kjoreotyliste, oblater } = this.props;
    const { oblattype, singleKjoretoy, singleBeskrivelse, singleKjoreotyType, singleOblattype, kunInnevaerendeSesong } =
      this.state;

    return (
      <section className="oblatregistrering-page">
        <h1 className="oblatregistrering-title">Registrer oblater</h1>
        <MengdekjopForm
          oblattype={oblattype}
          kjoreotylisteLength={kjoreotyliste ? kjoreotyliste.length : 0}
          kjoretoyliste={kjoreotyliste}
          oblater={oblater}
          uploadFile={this.uploadFile}
          onRadioBtnClick={this.onRadioBtnClick}
          confirmKjoretoyliste={this.confirmKjoretoyliste}
          resetKjoretoyliste={this.resetKjoretoyliste}
        />
        {kjoreotyliste.length > 0 && (
          <Kjoreotytabell
            kjoreotyliste={kjoreotyliste}
            opdaterKjoreotyListeType={opdaterKjoreotyListeType}
            slettKjoretoyoyFraListe={slettKjoretoyoyFraListe}
          />
        )}
        <EnkeltkjopForm
          onChange={this.onChange}
          singleKjoretoy={singleKjoretoy}
          singleBeskrivelse={singleBeskrivelse}
          singleKjoreotyType={singleKjoreotyType}
          singleOblattype={singleOblattype}
          oblater={oblater}
          confirmKjoretoyliste={this.confirmsingleKjoretoy}
        />
        <div className="">
          <div className="kun-innevaerende-sesong-checkbox">
            <input
              type="checkbox"
              checked={kunInnevaerendeSesong}
              onChange={() => this.onKunInnevaerendeSesongChangeClick()}
            />{" "}
            Kun inneværende sesong
          </div>
          <div>{oblater && oblater.length > 0 && <Oblattabell oblater={oblater} />}</div>
        </div>
      </section>
    );
  }
}

export default Oblatregistrering;
