import Api from "../api";
import { toastSuccess } from "./toast";
import { find } from "lodash-es";

// types
const GET_OBLATTYPER = "GET_OBLATTYPER";
const GET_KJORETOYTYPER = "GET_KJORETOYTYPER";
const GET_PERIODER = "GET_PERIODER";
// const POST_OBLATTYPER = "POST_OBLATTYPER";
// const UPDATE_OBLATTYPER = "UPDATE_OBLATTYPER";
// const DELETE_OBLATTYPER = "DELETE_OBLATTYPER";

export default function reducer(
  state = {
    oblattyper: [],
    kjoretoytyper: [],
    perioder: []
  },
  action = {}
) {
  if (action.type === GET_OBLATTYPER) {
    return {
      ...state,
      oblattyper: action.payload
    };
  } else if (action.type === GET_KJORETOYTYPER) {
    return {
      ...state,
      kjoretoytyper: action.payload
    };
  } else if (action.type === GET_PERIODER) {
    return {
      ...state,
      perioder: action.payload
    };
  }
  return state;
}

const getOblattyperSuccess = payload => {
  return { type: GET_OBLATTYPER, payload };
};
const getKjoretoyTyperSuccess = payload => {
  return { type: GET_KJORETOYTYPER, payload };
};
const getPerioderSuccess = payload => {
  return { type: GET_PERIODER, payload };
};

export function getOblattyper() {
  return dispatch => Api.getOblattyper().then(response => dispatch(getOblattyperSuccess(response.data.result)));
}

export function postOblattype(payload) {
  return dispatch =>
    Api.postOblattype(payload).then(() => {
      dispatch(getOblattyper());
      dispatch(toastSuccess("Opprettet oblattype"));
    });
}

// {
//   "navn": "string",
//   "periodeId": 0,
//   "pris": 0,
//   "statusId": 0,
//   "utloper": "2018-10-24T08:22:00.104Z",
//   "kjoretoytypeId": 0,
//   "agressoArtikkelkode": "string"
// }

export function updateOblattype(id, payload) {
  return dispatch =>
    Api.putOblattype(id, payload).then(() => {
      dispatch(getOblattyper());
      dispatch(toastSuccess("Slettet oblattype"));
    });
}

export function getKjoretoyTyper() {
  return dispatch =>
    Api.getKjoretoyTyper().then(response => {
      dispatch(getKjoretoyTyperSuccess(response.data.result));
    });
}

export function getPerioder() {
  return dispatch =>
    Api.getPerioder().then(response => {
      dispatch(getPerioderSuccess(response.data.result));
    });
}

export const getOblattyperSelector = state => state.oblattyper.oblattyper;
export const getPerioderSelector = state => state.oblattyper.perioder;
export const getKjoretoytyperSelector = state => state.oblattyper.kjoretoytyper;
export function getOblatTypeByPeriodeIdAndKjoretoytypeId(state, periodeId, kjoretoytypeId) {
  const oblattyper = getOblattyperSelector(state);
  return find(
    oblattyper,
    oblattype => oblattype.periodeId === periodeId && oblattype.kjoretoytypeId === kjoretoytypeId
  );
}
