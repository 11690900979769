import Api from "../api";
import { toastSuccess, toastError } from "./toast";
import { sortBy } from "lodash-es";

// types
const GET_SYSTEMKONFIGURASJON = "GET_SYSTEMKONFIGURASJON";
// const POST_SYSTEMKONFIGURASJON = "POST_SYSTEMKONFIGURASJON";
// const UPDATE_SYSTEMKONFIGURASJON = "UPDATE_SYSTEMKONFIGURASJON";
// const DELETE_SYSTEMKONFIGURASJON = "DELETE_SYSTEMKONFIGURASJON";

export default function reducer(state = { systemkonfigurasjon: [] }, action = {}) {
  if (action.type === GET_SYSTEMKONFIGURASJON) {
    return {
      ...state,
      systemkonfigurasjon: action.payload
    };
  }
  return state;
}

const getSystemkonfigurasjonSuccess = payload => {
  return { type: GET_SYSTEMKONFIGURASJON, payload };
};

export function getSystemkonfigurasjon() {
  return dispatch =>
    Api.getSystemkonfigurasjon().then(response =>
      dispatch(getSystemkonfigurasjonSuccess(sortBy(response.data.result, i => i.id)))
    );
}

export function postSystemkonfigurasjon(navn, kategori, verdi) {
  return dispatch =>
    Api.postSystemkonfigurasjon(navn, kategori, verdi)
      .then(() => {
        dispatch(getSystemkonfigurasjon());
        dispatch(toastSuccess("Opprettet systemkonfigurasjon"));
      })
      .catch(error => {
        dispatch(toastError(error));
      });
}

export function deleteSystemkonfigurasjon(id) {
  return dispatch =>
    Api.deleteSystemkonfigurasjon(id).then(() => {
      dispatch(getSystemkonfigurasjon());
      dispatch(toastSuccess("Slettet systemkonfigurasjon"));
    });
}

export function updateSystemkonfigurasjon(id, payload) {
  return dispatch =>
    Api.putSystemkonfigurasjon(id, payload).then(() => {
      dispatch(getSystemkonfigurasjon());
      dispatch(toastSuccess("Oppdaterte systemkonfigurasjon"));
    });
}

export const getSystemkonfigurasjonSelector = state => state.systemkonfigurasjon;
export const getSystemkonfigurasjonerSelector = state => getSystemkonfigurasjonSelector(state).systemkonfigurasjon;
