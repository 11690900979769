import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import find from "lodash/find";
import moment from "moment";

import NyOblattypeForm from "./NyOblattypeForm";
import "./Oblattyper.css";

const Oblattyper = ({ oblattyper, postOblattype, perioder, kjoretoytyper }) => {
  const getStatus = statusId => {
    if (statusId === 1) return "AKTIV";
    if (statusId === 3) return "SKJULT";
    return "DEAKTIVERT";
  };

  return (
    <section className="oblattyper-page">
      <h1 className="oblattyper-title">Oblattyper</h1>

      <div className="oblattyper-body">
        <table>
          <tr>
            <th>Navn</th>
            <th>Periode</th>
            <th>Pris</th>
            <th>StatusId</th>
            <th>Utloper</th>
            <th>Kjoretoytype</th>
            <th>Agresso artikkelkode</th>
          </tr>

          {map(oblattyper, item => {
            const p = find(perioder, i => i.id === item.periodeId);
            const k = find(kjoretoytyper, type => type.id === item.kjoretoytypeId);

            return (
              <tr>
                <td>{item.navn}</td>
                <td>{p ? p.navn : item.periodeId}</td>
                <td>{item.pris}</td>
                <td>{getStatus(item.statusId)}</td>
                <td>{item.utloper ? moment(item.utloper).format("DD.MM.YYYY") : ""}</td>
                <td>{k ? k.navn : item.kjoretoytypeId}</td>
                <td>{item.agressoArtikkelkode}</td>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="oblattyper-add-form">
        <NyOblattypeForm postOblattype={postOblattype} perioder={perioder} kjoretoytyper={kjoretoytyper} />
      </div>
    </section>
  );
};

Oblattyper.propTypes = {
  oblattyper: PropTypes.arrayOf(PropTypes.shape({})),
  perioder: PropTypes.arrayOf(PropTypes.shape({})),
  kjoretoytyper: PropTypes.arrayOf(PropTypes.shape({})),
  postOblattype: PropTypes.func.isRequired
  // updateOblattype: PropTypes.func.isRequired
};

Oblattyper.defaultProps = {
  oblattyper: [],
  perioder: [],
  kjoretoytyper: []
};

export default Oblattyper;
