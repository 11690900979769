import React from "react";
import PropTypes from "prop-types";
import "./PublicContainer.css";

function Aktivering({ message }) {
  return (
    <div className="public-container">
      <section className="public-container-content">
        <h1>Aktivering av brukerkonto</h1>
        <span>{message}</span>
      </section>
    </div>
  );
}

Aktivering.propTypes = {
  message: PropTypes.string.isRequired
};

export default Aktivering;
