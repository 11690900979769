const admin = "/";
const brukerPage = "/brukere";

const systemkonfigurasjon = "/systemkonfigurasjon";
const oblatregistrering = "/oblatregistrering";

const oblattyper = "/oblattyper";

const kjopOblat = "/kjop-oblat";

const kjoretoy = "/kjoretoy";
const valgtKjoretoy = "/kjoretoy/:key";

const userSelected = "/brukere/:key";
const loginPage = "/login";
const activateUser = "/aktivering/:key";
const forgotPassword = "konto/passord";
const activateWithPassword = "passordaktivering";
const newPasswordPathMatchBoth = `/(${activateWithPassword}|${forgotPassword})/:key`;

const glemtPassord = "/glemtpassord";
const kunderPage = "/kunder";
const kundeSelected = "/kunder/:key";

const userSelectedWithKey = key => `/kunder/${key}`;

export default {
  admin,
  systemkonfigurasjon,
  brukerePage: brukerPage,
  userSelectedWithKey,
  loginPage,
  activateUser,
  userSelected,
  forgotPassword,
  activateWithPassword,
  newPasswordPathMatchBoth,
  kunderPage,
  kundeSelected,
  glemtPassord,
  oblattyper,
  kjopOblat,
  kjoretoy,
  valgtKjoretoy,
  oblatregistrering
};
